import client from 'api-client'
import {mergeDeep} from "../../tools.js";

export function DefaultReservation(){
  // Helper function to set shared 'default' new res object
  return {
    ReservationStatus: "new",
    Source: {
      Code: 'WEB'
    },
    VendorID: "",
    Rate: {},
    RateClass: "",
    Notes: [],
    Options: [],
    Renter: {
      Address: {}
    },
    Deposit: {
      CardNumber: "",
      PostCode: "",
      Expiry: "",
      CVV: "",
      Amount: 300
    }
  }
}

const state = {
  // The currently editing res
  // Include hard-coded res options like Source Code
  reservation: DefaultReservation(),

  estimate: {
    Customer: {
      Total: 0,
      CurrencyCode: '',
      Options: [
        {
          Code: '',
          Quantity: 0,
          CurrencyCode: '',
          Description: '',
          RateExclCharge: 0,
          SubTotal: 0,
          Total: 0,
          Tax: 0
        }
      ],
      TimeCharges: [
        {
          Quantity: 0,
          CurrencyCode: '',
          Description: '',
          RateExclCharge: 0,
          SubTotal: 0,
          Total: 0,
          Tax: 0
        }
      ],
      Distance: {
        RateExclTax: 0,
        RateInclTax: 0,
        Tax: 0,
        Included: 0,
        Unit: '',
        Unlimited: true,
        Estimated: 0,
      }
    }
  },
}

const getters = {
  reservation (state){
    return state.reservation
  },
}

const mutations = {
  clearReservation(state){
    state.reservation = DefaultReservation()
  },
  setReservation(state, reservation){
    state.reservation = reservation
  },
  updateReservation(state, partialReservation){
    mergeDeep(state.reservation, partialReservation)
  },
  setEstimate (state, estimate){
    state.estimate = estimate
  },
}

const actions = {
  async createReservation({ commit }, data) {
    commit('clearErrors', null, { root: true });
    commit('setLoading', true, { root: true });

    try {
      // Execute reCAPTCHA and get the token
      const recaptchaToken = await this._vm.$executeRecaptcha();

      // Include the reCAPTCHA token in the data
      data.params.RecaptchaToken = recaptchaToken;

      const response = await client.createReservation(data.optionsRequestId, data.params);
      commit('updateReservation', response);
      return Promise.resolve(response);
    } catch (error) {
      commit('setErrors', error, { root: true });
      return Promise.reject(error);
    } finally {
      commit('setLoading', false, { root: true });
    }
  },
  cancelReservation({commit}, reservationNumber){
    commit('setLoading', true, { root: true });
    return client
      .cancelReservation(reservationNumber)
      .then((response) => {
        commit('updateReservation', {
          "ReservationStatus":"cancelled"
        });
        return Promise.resolve(response)
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  editReservation({commit}, data){
    commit('setLoading', true, { root: true });
    return client
      .updateReservation(data.reservationNumber, data.params)
      .then((response) => {
        commit('updateReservation', response);
        return Promise.resolve(response)
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  getReservation({dispatch, commit}, reservationNumber){
    commit('setLoading', true, { root: true });
    return client
      .getReservation(reservationNumber)
      .then((reservation) => {
        commit('setReservation', reservation);
        /* auto get a res estimate, as there won't be one from $state */
        if (reservation.ReservationStatus === 'open'){
          dispatch('getReservationEstimate', {reservationNumber: reservationNumber});
        }
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  getEstimate({commit, state}){
    let optionsRequestId = this.getters['options/optionsRequestId'];
    let RateClass = state.reservation.Rate.Class;
    let Options = state.reservation.Options;
    let EstimatedDistance = state.reservation.EstimatedDistance;
    if (!optionsRequestId){
      // Avoid error when calling out-of-flow
      // i.e. during template development
      return;
    }
    commit('setLoading', true, { root: true });
    return client
      .estimate(optionsRequestId, {RateClass, Options, EstimatedDistance})
      .then((estimate) => {
        commit('setEstimate', estimate);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  getReservationEstimate({commit}, data){
    commit('setLoading', true, { root: true });
    // Always update the estimate to match the res on the state
    let res = this.state.reservation;
    let estimateParams = {
      Voucher: res.Voucher,
      RateClass: res.RateClass,
      EstimatedDistance: res.EstimatedDistance
    };
    return client
      .getReservationEstimate(data.reservationNumber, estimateParams)
      .then((estimate) => {
        commit('setEstimate', estimate);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      /* auto get a res estimate, as there won't be one from $state */
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}