<template>
  <v-container>
  </v-container>
</template>

<script>

export default {
    name: 'ItineraryPage',
    components: {
    }
}

</script>
