<template>
  <div>
  <v-sheet tile >
    <template v-if="loading">
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </template>
    <h1 class="d-sr-only">Flexible Dates</h1>
    <v-row class="mb-0">
      <v-toolbar flat color="white">
        <v-btn icon @click="prev" aria-label="Previous Month (content will update below)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click="next" aria-label="Next Month (content will update below)">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title class="mx-2">{{ title }} </v-toolbar-title>
        <v-btn icon @click="setToday" aria-label="Go to today (content will update below)">
          <v-icon>mdi-calendar-today</v-icon>
        </v-btn>
      </v-toolbar>
      <ClassSwitch :currentMonth="calendarStart"></ClassSwitch>
    </v-row>
  </v-sheet>
  <v-sheet :height="calendarHeight" class="white calendarsheet">
    <v-calendar

      ref="calendar"
      type="month"
      v-model="focus"
      :events="calendarEvents"
      :event-more="false"
      :event-color="getEventColor"
      :event-text-color="getEventTextColor"
      @click:event="showEvent"
      @change="calendarChange"
      @moved="calendarMoved"
    >
      <template v-slot:day-label="{date}">
        {{ date | moment('D') }}
      </template>
      <template v-slot:event="{event}">
          <span v-if="event.name == 'CLOSED'" class="d-block closedevent my-6">
            {{ event.name }}
          </span>
          <span v-else-if="!event.rate" class="d-block my-6">
            {{ event.name }}
          </span>
          <button v-else class="rateevent">
            <template v-if="event.rate.Availability == 'Available'">
              <span class="headline font-weight-bold mb-0">
                <template v-if="showNightly">
                {{ calcNightlyRate(event.rate) | price }}
                </template>
                <template v-else>
                {{ event.rate.Rental.Estimate | price }}
                </template>
              </span>
              <span class="d-block duration">
                <template v-if="showNightly">
                  per night
                </template>
                <template v-else>
                  total
                </template>
                for {{ rateDuration(event.rate) }} nights
                <span class="d-sr-only" v-if="rateEventIsSelected(event)">
                  selected
                </span>
              </span>
            </template>
            <div v-if="event.rate.Availability != 'Available'">
              <span class="limitedavailability-large">
                Limited Availability
                <br>Call <a href="tel:+18006718042">800-671-8042</a></span>
              <span class="limitedavailability-small">Call</span>
            </div>
          </button>
      </template>

    </v-calendar>
    <v-dialog v-model="selectedOpen" max-width="350"
      :activator="selectedElement"
      attach
    >
      <v-card v-if="modalRate" color="grey lighten-4" flat>
        <v-toolbar dark>
          <v-toolbar-title>
            <h2 class="text-h6 white--text">
            <template v-if="modalRate.Availability == 'Available'">
              <template v-if="showNightly">{{ calcNightlyRate(modalRate) | price }} per night for {{ rateDuration(modalRate) }} nights</template>
              <template v-else>{{ modalRate.Rental.Estimate | price }} for {{ rateDuration(modalRate) }} nights</template>
            </template>
            <template v-if="modalRate.Availability != 'Available'">
              Limited Availability
            </template>
            </h2>
            </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="selectedOpen = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-3">
          <b>{{ modalRate.rateQuery.Pickup.DateTime | moment("MMMM Do") }} to {{ modalRate.rateQuery.Return.DateTime | moment("MMMM Do") }}</b>
          <ul class="pt-4" v-if="modalRate.Availability == 'Available'">
            <li v-if="modalRate.Rental.Liability">
              Liability: {{ modalRate.Rental.Liability | price }}
            </li>
            <li v-if="modalRate.Rental.OneWayFee">
              OneWayFee: {{ modalRate.Rental.OneWayFee | price }}
            </li>
            <li v-if="modalRate.Rental.Distance.Unlimited">
              Unlimited {{mileOrKM}}
            </li>
            <li v-else>
              <template v-if="modalRate.Rental.Distance.Included">{{ modalRate.Rental.Distance.Included }} {{mileOrKM}}s included,</template>
              plus {{ modalRate.Rental.Distance.ExtraRate | price }} per additional {{mileOrKM}}
            </li>
          </ul>
          <template v-if="modalRate.Availability != 'Available'">
          <h5 class="mt-4">Limited availability; Please call <a href="tel:+18006718042">800-671-8042</a> </h5>
          </template>
        </v-card-text>
        <template v-if="modalRate.Availability == 'Available'">
          <v-card-actions class="mx-1">
            <v-spacer></v-spacer>
            <v-btn color="secondary primary" class="elevation-0" @click="chooseRate(modalRate)">Book Now</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-sheet>
</div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import ClassSwitch from '../components/ClassSwitch.vue'
import moment from "moment";
import {pad} from "../tools.js";
export default {
  name: 'RatesDisplay',
  components: {
    ClassSwitch
  },
  computed: {
     ...mapState({
      loading: 'loading',
      rateQuery: state => state.rates.rateQuery,
      rates: state => state.rates.rates,
      flexibleRates: state => state.rates.flexibleRates,
      flexRatesClass: state => state.rates.flexRatesClass,
      locations: state => state.config.rentingLocations,
      loadingOpeningHours: state => state.locations.loadingOpeningHours,
      reservation: state => state.reservation.reservation,
      errorMessages: state => state.config.errorMessages,
    }),
     ...mapGetters({
      getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
    }),
    location () {
      if (!this.rateQuery.Pickup) return
      let locationCode = this.rateQuery.Pickup.Location
      return this.locations.find(el=>{
        return el.LocationNumber == locationCode
      })
    },
    country () {
      if (this.location){
        return this.location.CountryCode
      }
      return 'US'
    },
    mileOrKM () {
      if (this.country === 'CA'){
        return "KM"
      }
      else {
        return "mile"
      }
    },
    title () {
      if (!this.start){
        return this.calendarStart
      }

      const startYear = this.start.year
      const startMonth = this.monthFormatter(this.start)

      return `${startMonth} ${startYear}`
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'short',
      })
    },
    calendarHeight () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return '500';
      }
      return '600'
    },
    currentFlexRates () {
      let rates = []
      if (this.flexibleRates[this.rateQuery.Pickup.Location] && this.flexibleRates[this.rateQuery.Pickup.Location][this.flexRatesClass]){
        rates = this.flexibleRates[this.rateQuery.Pickup.Location][this.flexRatesClass]
      }
      return rates
    },
    calendarEvents () {
      // init list with 'closed' day
      let list = []
      list.push(...this.monthlyClosedDays)
      let flexRatesLength = this.currentFlexRates.length
      if (flexRatesLength < 1){
        return list
      }

      let flexRates = this.currentFlexRates
      for (let i = 0; i<flexRates.length; i++){
        let r = this.rateToEvent(flexRates[i]);

        // We want the originl chosen rate to be a different colour
        if (flexRates[i].rateQuery.Pickup.DateTime === this.rateQuery.Pickup.DateTime){
          r.textColor = 'white'
          r.color = 'primary'
        }
        list.push(r);
      }
      return list;
    },
    monthlyClosedDays (){
      let list = []
      if (!this.start){
        return list
      }
      // if still loading, don't show every day as closed
      if (this.loadingOpeningHours){
        return list
      }
      let monthStart = this.start.date.substring(0,8)
      let monthlength = moment(this.start.date).daysInMonth();

      for (let i = 0; i <= monthlength; i++) {
        // avoid daylight savings issue by iterating the days without Date object help
        let day = pad(i+1)
        let eachDay = `${monthStart}${day}`
        let open = this.getOpeningTimesForDate(eachDay, this.rateQuery.Pickup.Location)
        if (!open || !open.length){
          list.push({
            name: 'CLOSED',
            start: eachDay,
            color: 'transparent',
            textColor: 'grey darken-2',
          })
        }
      }
      return list
    },
    showNightly () {
      // Force build not to optimise out this check
      let configured = process.env.VUE_APP_SHOW_NIGHTLY_RATE_ON_CALENDAR
      if (configured == 'true'){
        return true
      }
      return false
    },
  },
  data () {
    return {
      modalVisible: false,
      modalRate: null,
      calendarStart: '',
      start: null,
      today: null,
      focus: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    }
  },
  methods: {
    ...mapMutations({
      updateRateQuery: 'rates/updateRateQuery',
      updateReservation: 'reservation/updateReservation',
      setRates: 'rates/setRates'
    }),
    ...mapActions({
      getMonthOfRates: 'rates/getMonthOfRates'
    }),
    showEvent ({ nativeEvent, event }) {
      // let them click on call directly without a popup on desktop
      if (event.rate.Availability != 'Available' && this.$vuetify.breakpoint.mdAndUp){
        return
      }

      const open = () => {
        this.selectedEvent = event
        this.modalRate = event.rate
        this.selectedElement = nativeEvent.target

        this.$nextTick(() => {
          this.selectedOpen = true, 10
        })
      }

      if (this.modalVisible) {
        this.modalVisible = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    chooseRate (Rate) {
      // Set the rate on the current reservation
      var newResDetails = {
        "Rate": Rate,
        "RateClass": Rate.Class,
        "PackageCode": Rate.rateQuery.PackageCode,
        // copy the chosen rate itinerary to the current res
        "Pickup": Rate.rateQuery.Pickup,
        "Return": Rate.rateQuery.Return,
        "Passengers": this.rateQuery.Passengers,
        // default the distance to the one supplied, the user may change it
        "EstimatedDistance": Rate.Rental.Distance.Estimated
      }
      this.updateReservation(newResDetails)
      // copy the selected rateQuery to the rates object
      let newrates = {
        Rates: [Rate],
        ratesRequestId: Rate.ratesRequestId
      }
      this.setRates(newrates)
      this.updateRateQuery(Rate.rateQuery)
      this.$router.push({path: '/options'})
    },
    getEventColor (event) {
      return event.color
    },
    getEventTextColor (event) {
      return event.textColor
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    setToday () {
      this.focus = this.today
    },
    rateTitle (rate){
      let r = rate.Rates[0];
      if (!r.Rental.Estimate){
        return `${this.rateDuration(rate)} Nights - Limited Availability`
      }
      if (this.showNightly){
        let nightly = this.calcNightlyRate(r)
        nightly = this.$options.filters.price(nightly)
        return `${nightly} per night for ${this.rateDuration(rate)} nights`
      }
      let estimate = this.$options.filters.price(r.Rental.Estimate)
      return `${estimate} for ${this.rateDuration(rate)} nights`
    },
    rateDuration(rate){
      let a = moment(rate.rateQuery.Return.DateTime.substring(0,10));
      let b = moment(rate.rateQuery.Pickup.DateTime.substring(0,10));
      return Math.ceil(a.diff(b,'days', true));
    },
    calcNightlyRate (rate) {
      if (!rate || !rate.Rental.RateOnlyEstimate) return 0
      return rate.Rental.RateOnlyEstimate / this.rateDuration(rate);
    },
    rateToEvent(rate){
      if (!rate) return
      if (rate.error){
        return {
          name: this.translateError(rate.error),
          allDay: true,
          start: rate.rateQuery.Pickup.DateTime.substring(0,10),
          textColor: 'red',
        }
      }
      let r = rate.Rates[0];
      r.rateQuery = rate.rateQuery;
      r.ratesRequestId = rate.ratesRequestId;
      return {
        name: this.rateTitle(rate),
        rate: r,
        allDay: true,
        start: rate.rateQuery.Pickup.DateTime.substring(0,10),
        textColor: 'grey darken-4',
      }
    },
    rateEventIsSelected(event){
      if (event.rate.rateQuery.Pickup.DateTime === this.rateQuery.Pickup.DateTime){
        return true
      }
      return false
    },
    translateError (error){
      let translatedError = this.errorMessages[error.Number.toString()]
      if (translatedError && translatedError.Text){
        return translatedError.Text
      }
      return error.Text
    },
    calendarChange ({ start }) {
      this.start = start
      this.calendarStart = start.date
    },
    calendarMoved (start){
      // trigger a month of rates
      this.getMonthOfRates(start.date)
    },
    updateCalendarFocus (){
      this.focus = this.rateQuery.Pickup.DateTime.substring(0,10)
    },
  },
  watch: {
    'rateQuery.Pickup.DateTime': 'updateCalendarFocus'
  },
  created () {
    if (!this.rateQuery || !this.rateQuery.Pickup.Location){
      this.$router.push({path: '/itinerary'})
    }
  },
  mounted () {
    this.$refs.calendar.checkChange()
    this.updateCalendarFocus()
  },
};

</script>
<style>
.theme--light.v-calendar-weekly .v-calendar-weekly__day {
  overflow: hidden;
}
.v-calendar-weekly__day-label {
  position: absolute;
  right: 0;
  z-index: 2;
  width: 40px;
  font-size: 12px;
  cursor: default;
}
.v-calendar-weekly__day-label .v-btn--fab.v-size--small {
  height: auto;
}
.calendarsheet {
  margin-bottom: 400px;
}
.v-calendar .v-event {
  height: 100% !important;
  width: 100% !important;
  text-align: center;
  overflow: visible;
  position: absolute;
  display: flex;
  top: 0px;
  border-radius: 0px;
  align-items: center;
  justify-content: center;
}
.rateevent:hover {
  opacity: 0.7;
}
.closedevent {
  transform: translateY(5px) rotate(315deg);
}
.v-event.primary {
  border: 1px dashed #82b1ff !important;
}
.v-event.primary a {
  color: #fff;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: #000;
}
@media (max-width: 1300px) {
  .v-calendar-weekly__day-label {
    margin-top: 0;
  }
  .v-calendar-weekly__day-label .v-btn--fab.v-size--small {
    width: 28px;
  }
}
@media (max-width: 960px) {
  .rateevent .headline {
    font-size: 1.1rem !important;
  }
  .rateevent .duration {
    font-size: 0.6rem;
    white-space: normal;
    line-height: 1.3em;
  }
  .closedevent {
    font-size: 10px;
  }
}
@media (max-width: 700px) {
  .rateevent .headline {
    font-size: 10px !important;
  }
  .rateevent .duration {
    display: none;
  }
  .limitedavailability-small {
    display: block;
    white-space: normal;
  }
  .limitedavailability-large {
    display: none;
  }
}
@media (min-width: 700px) {
  .limitedavailability-small {
    display: none;
  }
  .limitedavailability-large {
    display: block;
    white-space: normal;
    padding: 1em;
  }
}
@media screen and (max-width: 300px) {
  .v-calendar-weekly__head, .v-calendar-weekly__day.v-past,
  .v-calendar-weekly__day.v-outside {
    display: none;
  }
  .v-toolbar__content[style], .v-toolbar[style] {
    height: auto !important;
    display: block;
    margin-bottom: 1em;
  }
  .v-toolbar__content > * {
    display: inline-block;
  }
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child,
  .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child {
    margin-left: 0px;
  }
  .v-calendar-weekly__week,  .v-calendar-weekly, .limitedavailability-large {
    display: block;
  }
  .v-toolbar__title,
  .calendarsheet,
  .theme--light.v-calendar-weekly .v-calendar-weekly__day {
    overflow: unset !important;
    width: auto;
    white-space: pre-wrap;
  }
  .v-calendar-weekly__day-label {
    font-weight: bold;;
  }
  .v-calendar-weekly__day-label, .v-calendar .v-event {
    position: relative !important;
    width: auto;
  }
  .v-calendar-weekly {
    height: auto;
  }
  .closedevent {
    transform: none;
    margin: auto;
  }
  .calendarsheet {
    height: auto !important;
    margin-bottom: auto;
  }
  .v-calendar .v-event {
    justify-content: unset;
    display: inherit;
  }
}
</style>