<template>
  <div>
    <template v-if="$vuetify.breakpoint.xsOnly">
      <v-dialog ref="menu" v-model="showing" persistent width="290px">
        <template v-slot:activator="{ on }">
          <slot name="input" ref="input" :on="on"/>
        </template>
        <slot name="picker" ref="picker" :closeModal="closeModal" />
      </v-dialog>
    </template>
    <template v-else>
      <v-menu ref="menu" :role="false" v-model="showing" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" max-width="290px" attach>
        <template v-slot:activator="{ on }">
          <slot name="input" ref="input" :on="on"/>
        </template>
        <slot name="picker" ref="picker" :closeModal="closeModal" />
      </v-menu>
    </template>
  </div>
</template>
<script>
export default {
  name: 'ResponsiveDialog',
  data() {
    return {
      showing: false,
    }
  },
  methods: {
    closeModal() {
      this.showing = false;
      if (this.$refs.input){
        this.$refs.input.focus()
      }
    }
  }
}
</script>