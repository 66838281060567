import Vue from 'vue'
import Vuex from 'vuex'

/* store modules */
import config from './modules/config'
import locations from './modules/locations'
import reservation from './modules/reservation'
import rates from './modules/rates'
import options from './modules/options'

Vue.use(Vuex);

export default new Vuex.Store({

  modules: {
    config: config,
    locations: locations,
    reservation: reservation,
    rates: rates,
    options: options,
  },

  state: {
    errors: [],
    loading: false,
    seenOptions: false,
  },

  getters: {
  },

  mutations: {
    setErrors (state, error){
      // Normalise errors into an array of strings
      // Make sure every message is numbered for translation
      if (error.response && error.response.data){
        // Error object is an Array
        if (error.response.data.length) {
          for (var err of error.response.data){
            if (!err.Number) {
              err.Number = error.response.status
            }
            if (err.Text) {
              state.errors.push(err);
            }
          }
        }
        // Error object is one object
        else {
          if (error.response.data.message){
            state.errors.push({
              Number: error.response.data.code || error.response.status,
              Text: error.response.data.message
            });
          }
        }
      }
    },
    clearErrors (state){
      state.errors = [];
    },
    setLoading (state, value){
      state.loading = value
    },
    setSeenOptions (state, value){
      state.seenOptions = value
    },
  },
  actions: {
  }
})