<template>
<v-flex xs12 md6 lg4>
  <v-card height="100%" width="100%" class="elevation-0 option">
    <v-layout justify-space-between column fill-height>
      <v-card-title class="pb-0">
        <h2 class="mb-3 text-h3" :id="option.Code">{{ details.Title }}</h2>
      </v-card-title>
      <v-card-text>
        <v-layout row justify-center class="mb-4">
          <v-img :src="`${details.ImageURL}`" :contain="true" :aspect-ratio="16/9" max-width="400"></v-img>
        </v-layout>
        <p v-html="details.Description" ref="descriptiontext"></p>

          <v-row>
            <v-col v-if="!option.AllowQuantity">
              <v-checkbox :id="`${option.Code}-c`" label="Add" type="checkbox" v-model="checkboxValue" :aria-labelledby="labelledBy">
                <template v-slot:label :aria-labelledby="labelledBy">
                  Add to reservation
                </template>
              </v-checkbox>
            </v-col>
            <v-col v-if="option.AllowQuantity">
              <NumberInput v-model="Quantity" label="Quantity" v-bind:min="0" v-bind:max="999" :steppers="true"></NumberInput>
            </v-col>
            <v-col class="text-sm-right">
              <template v-if="details.CustomPriceString">
                {{ details.CustomPriceString }}
              </template>
              <template v-else>
              {{ option.SubTotal | price }}<template v-if="option.AllowQuantity"> each</template>
              </template>
            </v-col>
          </v-row>
      </v-card-text>
    </v-layout>
  </v-card>
</v-flex>
</template>
<script>

import { mapState } from 'vuex'
import NumberInput from '../components/NumberInput.vue'

export default {
  name: 'Options',
  components: {
    NumberInput
  },
  props: {
    option: {
      Code: String,
      Description: String,
      AllowQuantity: Boolean,
      Quantity: Number,
      CurrencyCode: String,
      DamageLiability: String,
      SubTotal: Number,
      Total: Number,
      Tax: Number
    }
  },
  computed: {
    ...mapState({
      optionDetails: state => state.config.optionDetails,
    }),
    details () {
      let detail = this.optionDetails[this.option.Code];

      if (!detail){
        // return a default set if not configured
        return {
          ImageURL: 'options_images/option.gif',
          Description: this.option.Description,
          Title: this.option.Code
        };
      }
      else {
        return detail;
      }
    },
    labelledBy(){
      return `${this.option.Code} ${this.option.Code}-c`
    },
  },
  methods: {
    increment () {
      this.Quantity = parseInt(this.Quantity, 10) + 1;
    },
    decrement () {
      this.Quantity = parseInt(this.Quantity,10) - 1;
    },
    popup (event) {
      let href = event.currentTarget.href
      window.open(href, 'popup','scrollbars=yes,width=480,height=500')
    },
  },
  watch: {
    Quantity () {
      // Send the quantity to the parent
      let option = {
        Code: this.option.Code,
        Quantity: this.Quantity
      }
      this.$emit('select-option', option);
    },
    checkboxValue () {
      if (this.checkboxValue){
        this.Quantity = 1;
      }
      else {
        this.Quantity = 0;
      }
    }
  },
  data () {
    return {
      Quantity: this.option.Quantity || 0,
      checkboxValue: !!this.option.Quantity
    }
  },
  mounted() {
    // in order to add popups to links in option descriptions,
    // get from v-html back to method handler
    let links = this.$refs['descriptiontext'].getElementsByTagName('a')
    let vue = this

    Array.from(links).forEach(function (link) {
      link.addEventListener('click', function(event) {
        event.preventDefault();
        vue.popup(event);
      })
    })
  }
};
</script>
<style>
.nobottompadding {
  padding-bottom: 0;
}
.option .text-h3{
  font-size: 1.875rem !important;
  font-weight: 700;
}
</style>