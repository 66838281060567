import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import recaptcha from '@/plugins/recaptcha';
import VueRouter from 'vue-router'

import store from './store'

import App from './App.vue'
import ItineraryPage from './pages/ItineraryPage.vue'
import RatesDisplayCalendarPage from './pages/RatesDisplayCalendarPage.vue'
import RatesDisplayPage from './pages/RatesDisplayPage.vue'
import OptionsPage from './pages/OptionsPage.vue'
import ReservationSummaryPage from './pages/ReservationSummaryPage.vue'
import CheckoutPage from './pages/CheckoutPage.vue'

Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

Vue.filter('price', function (value) {
  if (!value) return
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
})

const routes = [
  { meta: { step: 1 }, path: '/', component: ItineraryPage, alias: '/itinerary'},
  { meta: { step: 1 }, path: '/itinerary/:resNo', component: ItineraryPage },
  { meta: { step: 2 }, path: '/rates', name:'rates-list', component: RatesDisplayPage },
  { meta: { step: 2 }, path: '/flexible-rates', name:'rates-calendar', component: RatesDisplayCalendarPage },
  { meta: { step: 3 }, path: '/options', component: OptionsPage },
  { meta: { step: 4 }, path: '/checkout', component: CheckoutPage },
  { meta: { step: 5 }, path: '/confirmation/:resNo', component: ReservationSummaryPage },
  { path: '/reservation/:resNo', component: ReservationSummaryPage },
];

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: routes
});
Vue.use(recaptcha, { siteKey:  process.env.VUE_APP_RECAPTCHA_SITE_KEY, hideBadge: true });

new Vue({
  vuetify: vuetify,
  store: store,
  router: router,
  render: h => h(App)
}).$mount('#app')
