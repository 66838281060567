<template>
  <v-container class="rates">
    <section class="page-title"><h1 v-show="filteredRates.length">Choose an RV</h1></section>
    <template v-if="loading">
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </template>

    <v-card v-for="rate in filteredRates" v-bind:key="rate.Class" class="mb-4" elevation="0">
      <v-card-text>
        <VehicleDetails :rate="rate" ></VehicleDetails>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-row justify="end" align="center">
        <template v-if="!ratesResult.rateQuery.FlexibleRates">
          <v-col cols="12" sm="3" class="text-center">
            <p class="text-h3">No. of Nights: {{ rentalDuration }}</p>
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <template v-if="rate.Availability == 'Available'">
              <p class="text-h3">Nightly Rate: {{ calcNightlyRate(rate) | price }}</p>
            </template>

            <v-btn v-if="showFlexibleDates" light class="mt-1 elevation-0" @click="setFlexible(rate)">My dates are flexible <span class="d-sr-only">for {{ rate.veh.Title }}</span></v-btn>
            <p class="text-h5 red-text" v-if="rate.AvailableUnits && rate.AvailableUnits < 6">
              {{ rate.AvailableUnits }} RV<template v-if="rate.AvailableUnits > 1">s</template> Left
            </p>
          </v-col>
        </template>
          <v-col cols="auto">
            <template v-if="rate.Availability == 'Available'">
              <v-btn color="primary" class="elevation-0" :loading="loading" :disabled="loading" @click="chooseRate(rate)">Choose Vehicle <v-icon right>mdi-chevron-right</v-icon></v-btn>
            </template>
            <template v-else>
              <h5>Limited availability; Please call <a href="tel:+18006718042">800-671-8042</a></h5>
            </template>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <p class="text-center minheight" v-show="!loading && !filteredRates.length">
      No rates to show - please change your search criteria and click 'Check Pricing'.
    </p>

  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import VehicleDetails from '../components/VehicleDetails.vue'
import moment from "moment";

export default {
    name: 'RatesDisplayPage',
    components: {
      VehicleDetails
    },
    props: {
    },
    computed: {
      ...mapState({
        loading: 'loading',
        ratesResult: state => state.rates.rates,
        vehicleDescriptions: state => state.config.vehicleDescriptions,
      }),
      rentalDuration () {
        let a = moment(this.ratesResult.rateQuery.Return.DateTime.substring(0,10));
        let b = moment(this.ratesResult.rateQuery.Pickup.DateTime.substring(0,10));
        return Math.ceil(a.diff(b,'days', true));
      },
      showFlexibleDates () {
        // Force build not to optimise out this check
        let configured = process.env.VUE_APP_SHOW_FLEXIBLE_DATES
        if (configured == 'true'){
          return true
        }
        return false
      },
    },
    methods: {
      ...mapMutations({
        updateRateQuery: 'rates/updateRateQuery',
        setFlexRateClass: 'rates/setFlexRateClass',
        updateReservation: 'reservation/updateReservation',
        setPickupTime: 'rates/setPickupTime',
        setReturnTime: 'rates/setReturnTime',
      }),
      ...mapActions({
        setDuration: 'rates/setDuration',
      }),
      chooseRate (Rate) {

        // copy Included to Estimated if not populated
        if (Rate.Rental.Distance.Included && !Rate.Rental.Distance.Estimated){
          Rate.Rental.Distance.Estimated = Rate.Rental.Distance.Included
        }
        // Set the rate on the current reservation
        var newResDetails = {
          "Rate": Rate,
          "RateClass": Rate.Class,
          "PackageCode": this.ratesResult.rateQuery.PackageCode,
          // copy the chosen rate itinerary to the current res
          "Pickup": this.ratesResult.rateQuery.Pickup,
          "Return": this.ratesResult.rateQuery.Return,
          "Passengers": this.ratesResult.rateQuery.Passengers,
          // default the distance to the one supplied, the user may change it
          "EstimatedDistance": Rate.Rental.Distance.Estimated
        }
        this.updateReservation(newResDetails);

        if (this.ratesResult.rateQuery.FlexibleRates){
          // Put chosen class on flex rate query
          this.setFlexRateClass(Rate.Class)

          this.$router.push({path: '/flexible-rates'})
          this.$store.dispatch('rates/getMonthOfRates', this.ratesResult.rateQuery.Pickup.DateTime)
        }
        else {
          this.$router.push({path: '/options'})
        }
      },
      setFlexible (Rate){
        this.$store.commit('rates/recalculateDuration')
        this.$store.commit('rates/setDurationRequested', this.$store.state.rates.duration)
        this.ratesResult.rateQuery.FlexibleRates = true
        this.updateRateQuery({FlexibleRates: true})
        this.chooseRate(Rate)
      },
      calcNightlyRate (rate) {
        if (!rate.Rental.RateOnlyEstimate) return 0
        return rate.Rental.RateOnlyEstimate / this.rentalDuration;
      },
      filterRates () {
        // Hide RVs that can take fewer than the requested passengers
        let self = this;
        if (!this.ratesResult.Rates){
          this.filteredRates = []
          return
        }
        const passengers = self.ratesResult.rateQuery.Passengers || 1;
        this.filteredRates = this.ratesResult.Rates.filter(function(rate) {
          let veh = self.vehicleDescriptions[rate.Class]
          if (!veh) {
            rate.veh = {
              Title: rate.Class
            }
            return true
          }
          rate.veh = veh
          return passengers <= veh.Passengers
        })

        // Sort them into custom order for cruise
        this.filteredRates.sort(function(a, b) {

          const rank = function(rateClass){
            let veh = self.vehicleDescriptions[rateClass]
            if (veh && veh.SortIndex){
              return veh.SortIndex;
            }
            else {
              return self.vehicleDescriptions.length+1;
            }
          }

          const a_rank = rank(a.Class)
          const b_rank = rank(b.Class)
          if (a_rank < b_rank) {
            return -1;
          }
          if (a_rank > b_rank) {
            return 1;
          }
        })
      },

      process_query_strings (q) {
        // Require at least pickup_datetime, return_datetime and pickup_loc e.g.
        // rates?pickup_datetime=2021-04-19T14%3A00&return_datetime=2021-04-22T11%3A00&pickup_state=AK&pickup_loc=ANC
        // also parse return_loc, disc (promo)
        // pickup_state is cruise-specific, just for display

        if (q.pickup_datetime && q.return_datetime){
          this.setPickupTime(q.pickup_datetime.split('T')[1])
          this.setReturnTime(q.return_datetime.split('T')[1])
          this.updateRateQuery({
            from_query_string: true,
            Pickup:{DateTime: q.pickup_datetime+':00'},
            Return:{DateTime: q.return_datetime+':00'}
          })
        }
        if (q.pickup_datetime && q.duration){
          this.setPickupTime(q.pickup_datetime.split('T')[1])
          this.updateRateQuery({
            Pickup:{DateTime: q.pickup_datetime+':00'}
          })
          this.setDuration(parseInt(q.duration, 10))
        }
        if (q.pickup_state){
          this.updateRateQuery({Pickup:{State: q.pickup_state}})
        }
        if (q.pickup_loc){
          this.updateRateQuery({Pickup:{Location: q.pickup_loc }})
        }
        if (q.return_loc){
          this.updateRateQuery({Return:{Location: q.return_loc }})
        }
        else if (q.pickup_loc) {
          // default return loc to match pickup
          this.updateRateQuery({Return:{Location: q.pickup_loc }})
        }
        if (q.disc){
          this.updateRateQuery({ PackageCode: q.disc })
        }
        if (q.passengers){
          this.updateRateQuery({ Passengers: parseInt(q.passengers, 10) })
        }
        if (q.flexible){
          let flexbool = false
          if (q.flexible === 'true'){
            flexbool = true
          }
          this.updateRateQuery({FlexibleRates: flexbool})
        }
      },
    },
    watch: {
      ratesResult: {
        handler(){
          this.filterRates()
        },
        deep: true
      }
    },
    created () {
      // Create rateQuery from url params
      this.process_query_strings(this.$route.query)

      // Clear rateclass before fetching rates in case of returning to this page
      this.updateRateQuery({ RateClass: null })

      this.$store.dispatch('rates/getRates')
      .catch(() => {
        this.$router.push({path: '/itinerary'})
      })
    },
    data () {
      return {
        filteredRates: []
      }
    }
};

</script>
<style>
.rates .v-card:nth-child(odd){
  background-color: #fff;
}
.page-title {
  margin-bottom: 30px;
}
.rates .text-h3 {
  font-weight: 400;
  font-size: 24px !important;
  color: #3273AF;
  margin: 0;
}
.red-text {
  color: #D7282D;
}
</style>
