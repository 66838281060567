import Vue from 'vue'
import client from 'api-client'
import {mergeDeep} from "../../tools.js";
import {pad} from "../../tools.js";
import moment from "moment";

function breakUpISOString(d) {
  return {
    date: d.substr(0,10),
    time: d.substr(11,5)
  }
}

export function RateQueryCopyAndShiftDates(rateQuery, shiftDays, minimumPickupDate){
  let rateQueryCopy = JSON.parse(JSON.stringify(rateQuery));

  let pickup = breakUpISOString(rateQueryCopy.Pickup.DateTime)
  let pickupDate = new Date(rateQueryCopy.Pickup.DateTime).addDays(shiftDays).toISODateOnlyString();
  rateQueryCopy.Pickup.DateTime = `${pickupDate}T${pickup.time}:00.000Z`

  let ret = breakUpISOString(rateQueryCopy.Return.DateTime)
  let returnDate = new Date(rateQueryCopy.Return.DateTime).addDays(shiftDays).toISODateOnlyString();

  rateQueryCopy.Return.DateTime = `${returnDate}T${ret.time}:00.000Z`

  // Don't bother returning pickup dates in the past
  if (new Date(pickupDate) < new Date(minimumPickupDate.toISODateOnlyString())){
    return;
  }

  return rateQueryCopy;
}
export function RateQueryShiftReturnDate(rateQuery, shiftDays){
  let ret = breakUpISOString(rateQuery.Return.DateTime)
  let returnDate = new Date(rateQuery.Return.DateTime).addDays(shiftDays).toISODateOnlyString();

  rateQuery.Return.DateTime = `${returnDate}T${ret.time}:00.000Z`
  return rateQuery;
}

const state = {
  rates: {},
  flexibleRates: {},
  flexRatesClass: "",
  pickupTime: client.initialPickupTime(),
  returnTime: client.initialReturnTime(),
  duration: 10,
  durationRequested: 3,
  minimumPickupDate: client.initialPickupDate(),
  minimumRentalPeriod: client.minimumRentalPeriod(),
  maximumRentalPeriod: client.maximumRentalPeriod(),
  rateQuery:{
    Pickup:{
      State: "",
      Location: "",
      DateTime: client.initialPickupDate().formatZulu()
    },
    Return:{
      Location: "",
      DateTime: client.initialDropOffDate().formatZulu()
    },
    FlexibleRates: false,
    flexibleRateID: "",
    PackageCode: "",
    RateClass: "",
    RateClasses: [],
    Source:{
      CountryCode: ""
    },
    Passengers:1,
  }
}

const getters = {
  rateQueryisValid (state) {
    // Simple check for values in the rateQuery
    return !!state.rateQuery.Pickup.Location
        && !!state.rateQuery.Return.Location;
  },
  getDuration (state) {
    return state.duration
  },
}

const mutations = {
  setRates (state, rates) {
    state.rates = rates
  },
  setFlexibleRates (state, rates){
    state.flexibleRates = rates
  },
  setFlexRateClass (state, newClass){
    state.flexRatesClass = newClass
  },
  addFlexibleRates (state, rates){
    if (!state.flexibleRates[rates.rateQuery.Pickup.Location]){
      // use Vue setter so that computed properties will update
      Vue.set(state.flexibleRates, rates.rateQuery.Pickup.Location, [])
    }
    if (!state.flexibleRates[rates.rateQuery.Pickup.Location][rates.rateQuery.RateClass]){
      Vue.set(state.flexibleRates[rates.rateQuery.Pickup.Location], rates.rateQuery.RateClass, [])
    }
    state.flexibleRates[rates.rateQuery.Pickup.Location][rates.rateQuery.RateClass].push(rates)
  },
  updateRateQuery(state, rateQuery){
    mergeDeep(state.rateQuery, rateQuery)
  },
  setReturnDate(state, value){
    state.rateQuery.Return.DateTime = value;
  },
  setPickupDate(state, value){
    state.rateQuery.Pickup.DateTime = value;
  },
  setPickupTime(state, value){
    state.pickupTime = value
  },
  setReturnTime(state, value){
    state.returnTime = value
  },
  recalculateDuration(state){
    let a = moment(state.rateQuery.Return.DateTime.substring(0,10));
    let b = moment(state.rateQuery.Pickup.DateTime.substring(0,10));
    state.duration = Math.ceil(a.diff(b,'days', true));
  },
  setDurationRequested(state, value){
    state.durationRequested = value
  }
}

const actions = {
  clearRates ({commit}){
    commit('setRates', []);
    commit('setFlexibleRates', {})
    commit('updateRateQuery', { flexibleRateID: '' });
  },

  getRates({commit, state, getters}){
    commit('clearErrors', null, {root:true})
    // get a list of rates for all classes
    if (!getters.rateQueryisValid){
      // Silently reject so rates requests
      // that would fail do not get sent
      return Promise.reject()
    }
    const flex = state.rateQuery.FlexibleRates
    commit('setLoading', true, { root: true });

    // Add a deep copy of this rate query
    // so we can modify the first one
    let rateQuery = JSON.parse(JSON.stringify(state.rateQuery));
    // insert the return times here so duration isn't affected by them
    let pickupDate = rateQuery.Pickup.DateTime.substring(0,10)
    rateQuery.Pickup.DateTime = `${pickupDate}T${state.pickupTime}:00.000Z`
    let returnDate = rateQuery.Return.DateTime.substring(0,10)
    rateQuery.Return.DateTime = `${returnDate}T${state.returnTime}:00.000Z`
    return client
      .rates(rateQuery)
      .then(rates => {
        // but keep the params that refer to these rates
        rates.rateQuery = rateQuery;
        commit('setRates', rates);
        if (flex){
          // set a simple ID to associate the flex rates with the original
          commit('updateRateQuery', {flexibleRateID: new Date().getTime() });
        }
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      })
  },
  async getMonthOfRates({state, dispatch}, calendarStartDate){
    // get flex rates for an entire month

    // First copy the current rateQuey to get the class, cdpid etc
    let rateQueryCopy = JSON.parse(JSON.stringify(state.rateQuery));

    // only get one class at a time, the chosen flex rates class
    rateQueryCopy.RateClass = state.flexRatesClass

    // if not supplied, use pickup date
    if (!calendarStartDate){
      calendarStartDate = rateQueryCopy.Pickup.DateTime
    }
    // force it to be first day of the month
    calendarStartDate = calendarStartDate.substr(0,8)+'01'

    // calculate offsets as an entire month of days
    let offsets = []
    let momentMonth = moment(calendarStartDate)
    let monthlength = momentMonth.daysInMonth();
    for (let i = 0; i < monthlength; i++) {
      offsets[i] = i
    }

    // Set pickupDate to first of the month
    let pickupDate = breakUpISOString(calendarStartDate).date
    let pickupTime = client.initialPickupTime()
    rateQueryCopy.Pickup.DateTime = `${pickupDate}T${pickupTime}:00.000Z`

    // Set returnDate to pickupDate + duration
    let returnDate = new Date(pickupDate).addDays(state.durationRequested).toISODateOnlyString();
    let returnTime = state.returnTime
    rateQueryCopy.Return.DateTime = `${returnDate}T${returnTime}:00.000Z`

    dispatch('doBatchRates', {offsets: offsets, rateQuery: rateQueryCopy})
  },
  async getFlexibleRates({state, dispatch}){
    // get flex rates based on the initial rateQuery
    // initial offsets are configured in flexratesoffsets.json
    let offsets = await client.calenderOffsets()
    dispatch('doBatchRates', {offsets: offsets, rateQuery: state.rateQuery})
  },
  async doBatchRates({commit, state}, options){
    let offsets = options.offsets
    let rateQuery = options.rateQuery

    // Transform rate query to create a queue of rates around it
    let queries = [];
    for (let i = 0; i < offsets.length; i++) {
      let r = RateQueryCopyAndShiftDates(rateQuery, offsets[i], state.minimumPickupDate)
      if (r){

        let openPickup = this.getters['locations/getOpeningTimesForDate'](r.Pickup.DateTime, r.Pickup.Location)
        let openReturn = this.getters['locations/getOpeningTimesForDate'](r.Return.DateTime, r.Pickup.Location)

        // try extending the res by up to 4 days
        if (openPickup !== null && openReturn === null){
          for (let j = 1; j < 4; j++) {
            r = RateQueryShiftReturnDate(r, 1) // cumulative, so do 1 at a time
            openReturn = this.getters['locations/getOpeningTimesForDate'](r.Return.DateTime, r.Pickup.Location)
            if (openReturn){
              break;
            }
          }
        }

        if (openPickup!==null && openReturn!==null){

          // TH-688 If loc closes before pickup time
          // pickup 2 hours before closing time

          let pickupDate = breakUpISOString(r.Pickup.DateTime).date
          let defaultPickupTime = client.initialPickupTime()
          let defaultPickupDateTime = new Date(`${pickupDate}T${defaultPickupTime}:00.000Z`)

          let closingTime = openPickup[0].EndHour
          let closingDateTime = new Date(`${pickupDate}T${closingTime}:00.000Z`)

          if (defaultPickupDateTime > closingDateTime){
            let closingHour = parseInt(closingTime.substring(0,closingTime.indexOf(':')), 10)
            let newPickupHour = pad(closingHour - 2)
            r.Pickup.DateTime = `${pickupDate}T${newPickupHour}:00:00.000Z`
          }
          else {
            r.Pickup.DateTime = `${pickupDate}T${defaultPickupTime}:00.000Z`
          }

          queries.push({rateQuery: r, retries: 3})
        }
      }
    }

    let doQuery = async function(){
      let q = queries.pop()

      // abort the flex rate if the base rateQuery has been re-run
      if (q!==undefined && q.rateQuery.flexibleRateID === state.rateQuery.flexibleRateID){

        // check the query has not already got a result
        let abort = false
        let currentFlexRatesForLocation = state.flexibleRates[q.rateQuery.Pickup.Location]
        if (currentFlexRatesForLocation){
          let currentFlexRatesByClass = currentFlexRatesForLocation[q.rateQuery.RateClass]
          if (currentFlexRatesByClass && currentFlexRatesByClass.length){
            currentFlexRatesByClass.forEach(function(flexRate){
               if (JSON.stringify(q.rateQuery.Pickup) === JSON.stringify(flexRate.rateQuery.Pickup)
                && JSON.stringify(q.rateQuery.Return) === JSON.stringify(flexRate.rateQuery.Return)){
                  abort = true
               }
            })
          }
        }
        if (abort){
          return
        }

        client
            .rates(q.rateQuery)
            .then(rates => {
              if (q.rateQuery.flexibleRateID === state.rateQuery.flexibleRateID){
                // Add a deep copy of this rate query
                // so we can modify the first one
                // but keep the params that refer to these rates
                rates.rateQuery = JSON.parse(JSON.stringify(q.rateQuery))
                commit('addFlexibleRates', rates)
              }
            }, error => {
              if (error.response.status===400){
                if (error.response.data.length && error.response.data[0].Number){
                  let rates = {}
                  rates.rateQuery = JSON.parse(JSON.stringify(q.rateQuery))
                  rates.error = error.response.data[0]
                  commit('addFlexibleRates', rates)
                }
                return;
              }
              if (q.retries>1){
                q.retries--
                queries.push(q)
              }
            }).then(doQuery)
      }
    };

    for (let i = 0; i < 3; i++) {
      doQuery()
    }
  },
  setPickupDate ({commit, state, dispatch}, value){
    // get the current duration and set the end date to keep the duration the same
    if (state.rateQuery.from_query_string){
      commit('updateRateQuery', {from_query_string:false})
    }
    else {
      let days = state.duration;
      let newReturnDate = new Date(value.substring(0,10));
      let dateShifted = newReturnDate.addDays(days);
      dispatch('setReturnDate', dateShifted.toISODateOnlyString());
    }
    // Format as an ISO date with no timezone or ms
    let dt = value + `T${state.pickupTime}:00.000Z`;
    commit('setPickupDate', dt);
    commit('recalculateDuration');
  },
  setReturnDate ({commit, state}, value){
    // make sure the date is open if not move it forward to the first open date
    let d = this.getters['locations/getFirstOpenDate'](value, state.rateQuery.Pickup.Location);
    if (!d) {
      // not loaded - just set the sent in value. Useful for setting from query-string
      commit('setReturnDate', value + `T${state.returnTime}:00.000Z`);
      commit('recalculateDuration');
      return
    }

    let d2 = d.toISODateOnlyString();
    let dt = d2 + `T${state.returnTime}:00.000Z`;
    commit('setReturnDate', dt);
    commit('recalculateDuration');
  },
  setDuration ({commit, state, dispatch}, value){
    // Set the return date from the passed value
    commit('setDurationRequested', value)
    // which will recalculate the duration value on the store
    let newReturnDate = new Date(state.rateQuery.Pickup.DateTime.substring(0,10));
    let dateShifted = newReturnDate.addDays(value);
    dispatch('setReturnDate', dateShifted.toISODateOnlyString());
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}