<template>
  <v-col cols="12" lg="6" class="trip-details">
    <h2 class="mb-4 text-h5">Trip Details</h2>
    <v-row v-if="reservation.ReservationStatus !== 'open' && reservation.ReservationNumber">
      <v-col cols="6">Status</v-col>
      <v-col cols="6" class="font-weight-bold">{{ reservation.ReservationStatus }}</v-col>
    </v-row>
    <v-row v-if="reservation.ReservationNumber">
      <v-col cols="6">Confirmation Number</v-col>
      <v-col cols="6" class="font-weight-bold">{{ reservation.ReservationNumber }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">Pick Up Location</v-col>
      <v-col cols="6" class="font-weight-bold"><address v-html="pickupAddress"></address></v-col>
    </v-row>
    <v-row>
      <v-col cols="6">Drop Off Location</v-col>
      <v-col cols="6" class="font-weight-bold"><address v-html="pickupAddress"></address></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        Call Pickup Location {{ pickupPhone }} for departure arrangements prior to arrival.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">Pick Up Date</v-col>
      <v-col cols="6" v-if="reservation.Pickup" class="font-weight-bold">{{ reservation.Pickup.DateTime | moment("MMMM Do YYYY") }} {{ pickupTimeString }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">Drop Off Date</v-col>
      <v-col cols="6" v-if="reservation.Return" class="font-weight-bold">{{ reservation.Return.DateTime | moment("MMMM Do YYYY") }} before 11:00 AM</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">RV Type</v-col>
      <v-col cols="6" v-if="vehicle" class="font-weight-bold">{{ vehicle.Title }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">Number of Passengers</v-col>
      <v-col cols="6" class="font-weight-bold">{{ reservation.Passengers }}</v-col>
    </v-row>
    <v-row v-if="reservation.PackageCode">
      <v-col cols="6">Deal Code</v-col>
      <v-col cols="6" class="font-weight-bold">{{ reservation.PackageCode }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6"><a href="https://www.cruiseamerica.com/rent/webres/kpk" target="_blank" v-on:click.prevent="optionPopup">Kitchen Kit</a></v-col>
      <v-col cols="6" class="font-weight-bold">{{ kitchenKits }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6"><a href="https://www.cruiseamerica.com/rent/webres/kpp" target="_blank" v-on:click.prevent="optionPopup">Personal Kit</a></v-col>
      <v-col cols="6" class="font-weight-bold">{{ personalKits }}</v-col>
    </v-row>
    <v-row v-if="showDistance">
      <v-col cols="6">Total {{ distanceUnit }}s included with rental</v-col>
      <v-col cols="6" class="font-weight-bold">{{ includedDistance }}</v-col>
    </v-row>
    <v-row v-if="estimate.Customer.Distance.RateExclTax">
      <v-col cols="6">Additional {{ distanceUnit }} rate</v-col>
      <v-col cols="6" class="font-weight-bold">{{ estimate.Customer.Distance.RateExclTax }} per additional {{ distanceUnit }}.</v-col>
    </v-row>
  </v-col>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TripDetails',
  computed: {
    ...mapState({
      locations: state => state.config.rentingLocations,
      reservation: state => state.reservation.reservation,
      vehicleDescriptions: state => state.config.vehicleDescriptions,
      estimate: state => state.reservation.estimate,
    }),
    ...mapGetters({
      getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
    }),
    pickupLocation (){
      if (!this.reservation.Pickup){
        return {};
      }
      let pickupLocCode = this.reservation.Pickup.Location;
      return this.locations.find(loc =>{
        return loc.LocationNumber == pickupLocCode
      })
    },
    pickupOpenHours () {
      let openings = this.getOpeningTimesForDate(this.reservation.Pickup.DateTime, this.reservation.Pickup.Location)
      if (openings && openings.length){
        return openings[0]
      }
      // fallback if openinghours does not return
      return {
        EndHour: '16:00'
      }
    },
    pickupDateTime () {
      let pickupDate = this.reservation.Pickup.DateTime.substring(0,11)
      return `${pickupDate}${this.pickupOpenHours.EndHour}`
    },
    pickupTimeString () {
      let time = this.$options.filters.moment(this.pickupDateTime, "h:mm A")
      let hour = parseInt(this.pickupOpenHours.EndHour.split(':')[0], 10)
      if (hour >= 16){
        return 'between 1:00 PM and 4:00 PM';
      }
      else {
        return `before ${time}`
      }
    },
    pickupAddress () {
      let p = this.pickupLocation;
      if (!p || !p.Address) return;
      return `${p.Address.Street2},<br>${p.Address.City}, ${p.Address.State}, ${p.Address.Street3}`;
    },
    pickupPhone () {
      let p = this.pickupLocation;
      if (!p || !p.Address) return;
      return p.Address.ReservationPhone;
    },
    vehicle () {
      return this.vehicleDescriptions[this.reservation.RateClass];
    },
    showDistance(){
      // hide distance on trailers
      if (this.reservation.Rate.Rental.Distance.ExtraRate === 0 || this.reservation.RateClass.indexOf('TR') === 0){
        return false
      }
      return true
    },
    includedDistance (){
      // not the included distance but how many miles the user chose earlier
      return this.reservation.EstimatedDistance;
    },
    distanceUnit () {
      if (this.pickupLocation.CountryCode === 'CA'){
        return 'kilometer';
      }
      return 'mile';
    },
    kitchenKits () {
      let count = this.getOptionCount('KPK')
      if (count === 0){
        return 'No'
      }
      else {
        return 'Yes'
      }
    },
    personalKits () {
      let count = this.getOptionCount('KPP')
      if (count === 0){
        return 'No personal kits'
      }
      else {
        return `${count} personal kits`
      }
    },
  },
  methods: {
    getOptionCount (optionCode){
      let optionFromCode = this.reservation.Options.filter(function(option){
        if (option.Code === optionCode){
          return true;
        }
      });
      if (optionFromCode.length){
        return optionFromCode[0].Quantity;
      }
      else {
        return 0;
      }
    },
    optionPopup (event) {
      let href = event.currentTarget.href
      window.open(href, 'popup','scrollbars=yes,width=480,height=500')
    },
  }
};
</script>
<style>
.trip-details .col {
  padding-top: 0px;
  padding-bottom: 7.5px;
}
address {
  font-style: inherit;
}
</style>