<template>
  <v-container>
      <section class="page-title">
        <h1>Options</h1>
      </section>
      <template v-if="loading">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </template>
      <v-container grid-list-sm fluid fill-height>
        <v-layout row wrap>
          <!-- custom option for Miles, which is not really an Option item but has to appear here -->
          <DistanceOption v-if="showDistance && !loading" v-on:select-option="onSelectOption" :value="reservation.EstimatedDistance" :steppers="false"></DistanceOption>
          <OptionItem v-for="option in filteredOptions" v-bind:key="option.Code" :option="option" v-on:select-option="onSelectOption"></OptionItem>
        </v-layout>
      </v-container>
      <v-layout justify-end row>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="whitebutton mt-4 elevation-0" :loading="loading" :disabled="loading" @click="submit()">Next Step <v-icon right>mdi-chevron-right</v-icon></v-btn>
      </v-layout>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import OptionItem from '../components/OptionItem.vue'
import DistanceOption from '../components/DistanceOption.vue'

export default {
  name: 'OptionsPage',
  components: {
    OptionItem,
    DistanceOption
  },
  computed: {
    ...mapState({
      loading: 'loading',
      reservation: state => state.reservation.reservation,
      optionDetails: state => state.config.optionDetails,
      options: state => state.options.options,
      rates: state => state.rates.rates,
    }),
    filteredOptions () {
      const optionDetails = this.optionDetails;
      return this.options.filter(function(opt){
        let detail = optionDetails[opt.Code];
        if (!detail){
          return true;
        }
        return !detail.Hide;
      })
    },
    showDistance(){
      // hide distance on trailers
      if (this.reservation.Rate.Rental.Distance.ExtraRate === 0 || this.reservation.RateClass.indexOf('TR') === 0){
        return false
      }
      return true
    },
  },
  methods:{
    ...mapMutations({
      updateReservation: 'reservation/updateReservation',
      setSeenOptions: 'setSeenOptions'
    }),
    getOptions () {

      // Trap undefined objects in case of directly navigating here or page refresh
      if (!this.reservation ){
        this.$router.push({path: '/itinerary'})
        return;
      }
      // Options call requires ratesRequestId
      if (!this.reservation.RateClass || !this.rates || !this.rates.ratesRequestId){
        this.$router.push({path: '/rates'})
        return;
      }

      let rateClass = this.reservation.RateClass;
      let ratesRequestId = this.rates.ratesRequestId;

      let me = this;
      this.$store.dispatch('options/getOptions', {ratesRequestId, rateClass})
      .then(() => {
        // set selected options internal list from Options
        for (let i in me.options){
          let opt = me.options[i];
          if (opt.Quantity){
            me.selectedOptions[opt.Code] = opt.Quantity;
          }
        }
        me.getSelectedOptionsFromReservation()
      })
      .catch(() => {
        this.$router.go(-1)
      })
    },
    submit () {
      this.setSelectedOptionsOnReservation()
      this.setSeenOptions(true);

      // Determine whether to checkout now or go back to Summary page
      if (this.reservation.ReservationStatus === "new"){
        this.$router.push({path: '/checkout'})
      }
      else {
        this.$router.push({path: `/reservation/${this.reservation.ReservationNumber}`});
      }
    },
    onSelectOption(option){
      this.selectedOptions[option.Code] = option.Quantity;
      this.setSelectedOptionsOnReservation()
    },
    getSelectedOptionsFromReservation () {
      for (let i in this.reservation.Options){
        let option = this.reservation.Options[i];
        if (option.Code){
          this.selectedOptions[option.Code] = option.Quantity;
        }
      }
      // if EstimatedDistance not set
      if (!this.reservation.EstimatedDistance){
        if (this.showDistance){
          this.updateReservation({"EstimatedDistance": 300})
        }
        else {
          this.updateReservation({"EstimatedDistance": 0})
        }
      }
    },
    setSelectedOptionsOnReservation () {
      // Serialise into an array of objects
      let opts = [];
      for (let key in this.selectedOptions){
        opts.push({
          "Code":  key,
          "Quantity": this.selectedOptions[key]
        })
      }
      this.updateReservation({
        "Options": opts
      });
    },
  },
  created () {
    this.getOptions();
  },
  data () {
    return {
      selectedOptions: []
    }
  }
};
</script>