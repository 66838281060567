import client from 'api-client'

const state = {
  options: [],
  optionsRequestId: '',
}

const getters = {
  optionsRequestId (state){
    return state.optionsRequestId
  }
}

const mutations = {
  setOptions (state, options) {
    // If options are selected on the current res,
    // put those quantities on the list of available options
    if (options.Options && options.Options.Customer){

      if (this.getters['reservation/reservation'].Options.length){
        let resOptions = this.getters['reservation/reservation'].Options;
        for (let i in options.Options.Customer){
          let option = resOptions.filter(opt => opt.Code === options.Options.Customer[i].Code);
          if (option.length){
            options.Options.Customer[i].Quantity  = option[0].Quantity
          }
          else {
            options.Options.Customer[i].Quantity = 0
          }
        }
      }

      state.options = options.Options.Customer
    }
    state.optionsRequestId = options.optionsRequestId

  },
}

const actions = {
  getOptions({commit}, data){
    commit('setLoading', true, { root: true });
    return client
      .options(data.ratesRequestId, data.rateClass)
      .then((options) => {
        commit('setOptions', options);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}