import Vue from 'vue'
/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]){
                    Vue.set(target, key, {})
                }
                mergeDeep(target[key], source[key]);
            } else {
                Vue.set(target, key, source[key])
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function pad(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

// converts a moment.js object and returns the time hours and minutes as a string HH:mm
export function durationToTimeString(d){
    return `${pad(d.hours())}:${pad(d.minutes())}`
}

Date.prototype.toISODateOnlyString = function() {
    return this.toISOString().substr(0, 10);
};

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.formatZulu = function() {
    // Format an ISO datestring ignoring ms and TZ
    return this.getUTCFullYear() +
        '-' + pad(this.getUTCMonth() + 1) +
        '-' + pad(this.getUTCDate()) +
        'T' + pad(this.getUTCHours()) +
        ':' + pad(this.getUTCMinutes())
        +':00.000Z';
}