<template>
  <ResponsiveDialog>
    <template v-slot:input="{ on }">
      <v-text-field :value="formatDate" :prepend-icon="prependIcon" readonly v-on="on" :loading="loadingOpeningHours" :disabled="loadingOpeningHours" v-on:keyup.enter.prevent="$event.target.click()" @keydown.enter.prevent="" @focus="focused = true" @blur="focused = false">
        <template v-slot:label>
          {{ label }} <span class="d-sr-only">(Press Enter key to display the calendar below)</span>
        </template>
      </v-text-field>
    </template>
    <template v-slot:picker="{closeModal}">
      <v-date-picker v-model="localDate" no-title scrollable :min="minDate" :max="maxDate" :allowed-dates="allowedDates" :loading="loadingOpeningHours" :disabled="loadingOpeningHours" :show-current="false">
        <v-spacer></v-spacer>
        <v-checkbox v-if="showFlexibleDates" v-model="localFlexibleRates" hide-details class="mr-2 mb-1 mt-0 noborder" label="My dates are flexible"></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="font-weight-bold" @click="closeModal">ОК</v-btn>
      </v-date-picker>
    </template>
  </ResponsiveDialog>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import ResponsiveDialog from './ResponsiveDialog.vue'

export default {
  name: 'PickDateWithOpeningTimes',
  components: {
    ResponsiveDialog
  },
  props: {
    location: {
      type: Object,
    },
    label: {
      type: String,
    },
    date: {
      type: String,
      required: true
    },
    minDate: {
      type: String,
    },
    maxDate: {
      type: String,
    },
    flexibleRates: {
      type: Boolean
    }
  },
  data() {
    return {
      focused: false,
      dateMenu: false,
      localDate: this.date,
      localFlexibleRates: this.flexibleRates,
    }
  },
  computed: {
    ...mapState({
      locationOpenHours: state => state.locations.locationOpenHours,
      loadingOpeningHours: state => state.locations.loadingOpeningHours,
    }),
    ...mapGetters({
      getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
    }),
    prependIcon () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 'event';
      }
      return ''
    },
    formatDate () {
      return moment(this.localDate).format('L')
    },
    showFlexibleDates () {
      // Force build not to optimise out this check
      let configured = process.env.VUE_APP_SHOW_FLEXIBLE_DATES
      if (configured == 'true'){
        return true
      }
      return false
    },
  },
  methods: {
    allowedDates: function(val) {
      if (!this.location) return
      return this.getOpeningTimesForDate(val, this.location.LocationNumber)
    },

  },
  watch: {
    focused (v){
      this.$emit('focus', v)
    },
    dateMenu (v) {
      this.$emit('closeModal', v)
      this.$emit('focus', v)
    },
    date (v) {
      this.localDate = v
    },
    localDate () {
      this.$emit('update:date', this.localDate)
    },
    flexibleRates (v) {
      this.localFlexibleRates = v
    },
    localFlexibleRates () {
      this.$emit('update:flexibleRates', this.localFlexibleRates)
    },
  }
};
</script>