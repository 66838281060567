<template>
  <v-text-field v-model="Quantity" type="number" :label="label" :required="required" :min="min" :max="max" step="1" @blur="limit"></v-text-field>
</template>
<script>

export default {
  name: 'NumberInput',
  props: {
    steppers: Boolean,
    label: String,
    required: Boolean,
    value: Number,
    max: Number,
    min: Number,
  },
  methods: {
    limit () {
      if (this.Quantity > this.max){
        this.Quantity = this.max
      }
      if (this.Quantity < this.min){
        this.Quantity = this.min
      }
    }
  },
  watch: {
    value(v){
      this.Quantity = v;
    },
    Quantity () {
      // Send the quantity to the parent
      this.$emit('input', parseInt(this.Quantity, 10));
    },
  },
  data () {
    return {
      Quantity: this.value || this.min || 0,
    }
  }
};
</script>