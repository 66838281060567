<template>
 <v-flex xs12 md6 lg4>
    <v-card height="100%" width="100%" class="elevation-0 option">
      <v-layout justify-space-between column fill-height>
        <v-card-title class="pb-0">
          <h2 class="mb-3 text-h3">Distance</h2>
        </v-card-title>
        <v-layout row justify-center class="mb-4">
          <v-img src="https://cacdnstorage.blob.core.windows.net/cruise/images/webres/distance.svg" :contain="true" :aspect-ratio="16/9" max-width="400"></v-img>
        </v-layout>
        <v-card-text>
        <v-layout row class="mb-4">
          <p>Please enter the estimated distance travelled during your trip. Distance <template v-if="includedMiles">over {{ includedMiles }} {{ milesOrKM }}</template> will be charged at {{distanceRate}}&cent; per {{mileOrKM}}.
          </p>
        </v-layout>
          <table style="width:100%;">
            <tr>
              <td style="width:60%;">
                <NumberInput v-model="distance" :label="milesOrKM" v-bind:min="minDistance"></NumberInput>
              </td>
              <td style="width:30%;" class="text-sm-right">
                {{ distanceTotal | price }}
              </td>
            </tr>
          </table>
        </v-card-text>
      </v-layout>
    </v-card>
  </v-flex>
</template>
<script>

import { mapState, mapMutations, mapGetters } from 'vuex'
import NumberInput from '../components/NumberInput.vue'

export default {
  name: 'DistanceOption',
  components: {
    NumberInput
  },
  props: {
    value: Number
  },
  computed: {
    ...mapState({
      locations: state => state.config.rentingLocations,
      reservation: state => state.reservation.reservation,
    }),
    ...mapGetters({
      getDuration: 'rates/getDuration',
    }),
    mileOrKM () {
      if (this.country === 'CA'){
        return "KM"
      }
      else {
        return "mile"
      }
    },
    milesOrKM () {
      if (this.country === 'CA'){
        return "KM"
      }
      else {
        return "miles"
      }
    },
    distanceRate () {
      if (!this.reservation.Rate.Rental){
        return 35;
      }
      return this.reservation.Rate.Rental.Distance.ExtraRate * 100
    },
    distanceTotal () {
      return (this.distance - this.includedMiles) * (this.distanceRate / 100)
    },
    location () {
      if (!this.reservation.Pickup) return
      let locationCode = this.reservation.Pickup.Location
      return this.locations.find(el=>{
        return el.LocationNumber == locationCode
      })
    },
    rentalDuration () {
      return this.getDuration
    },
    includedMiles (){
      if (!this.reservation.Rate.Rental) return 0
      return this.reservation.Rate.Rental.Distance.Included || 0
    },
    minDistance (){
      return this.includedMiles || 50
    },
    country () {
      if (!this.location) return ''
      return this.location.CountryCode
    },
    currency () {
      let currency = 'USD';
      if (this.country === 'CA'){
        currency = 'CAD';
      }
      return currency
    },
    milesLink () {
      return `https://www.cruiseamerica.com/rent/webres/miles?country=${this.country}&unit=${this.milesOrKM}&distance=${this.distance}&currency=${this.currency}&rate=${this.distanceRate}&nights=${this.rentalDuration}`;
    },
  },
  methods: {
    ...mapMutations({
      updateReservation: 'reservation/updateReservation',
    }),
    popup () {
      let href = event.currentTarget.href
      window.open(href, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=480,height=300')
    },
  },
  watch: {
    value(v){
      this.distance = v
    },
    distance () {
      // Set the estimated milage on the res
      this.updateReservation({ EstimatedDistance: this.distance })
    },
  },
  data () {
    return {
      option: {
        Code: 'MILES'
      },
      distance: this.value
    }
  }
};
</script>