export default {
    install(Vue, options) {
        // Load reCAPTCHA script
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${options.siteKey}`;
        document.body.appendChild(script);

        // Add style to hide the reCAPTCHA badge if hideBadge option is true
        if (options.hideBadge) {
            const style = document.createElement('style');
            style.innerHTML = '.grecaptcha-badge { visibility: hidden; }';
            document.head.appendChild(style);
        }

        // Provide a method to execute reCAPTCHA
        Vue.prototype.$executeRecaptcha = function () {
            return new Promise((resolve) => {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(options.siteKey, { action: 'submit' }).then((token) => {
                        resolve(token);
                    });
                });
            });
        };
    },
};