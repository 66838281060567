<template>
<div>
<div id="checkPricingToggle" @click="toggleCollapse" data-toggle="collapsed" data-target="#moduleCheckPricing" aria-expanded="false" aria-controls="moduleCheckPricing" role="button">Check Pricing</div>
<div id="moduleCheckPricing" v-bind:class="{ collapsed: collapsed, collapsing: collapsing }" aria-labelledby="checkPricingToggle" data-parent="#checkPricingModuleParent">

<v-form v-on:submit.prevent @focus="focused = true" @blur="focused = false">
  <v-row no-gutters v-bind:class="shrinkMaybe">
    <v-col lg="auto" cols="12" class="location-column flex-grow-1">
      <v-select v-model="pickupState" :items="rentingStates" placeholder="State/Province" item-text="Name" item-value="Code" attach @focus="($event) => {$event.target.click()}" @blur="focused = false">
        <template v-slot:label>
          State/Province <span class="d-sr-only">Use the up and down arrows or start typing, then use the up and down arrows to select an option from the list</span>
        </template>
      </v-select>
    </v-col>
    <v-col lg="auto" cols="12" class="location-column flex-grow-1">
      <v-combobox v-model="pickupLocation" :items="filteredLocations" label="City" placeholder="City" item-text="Description" item-value="LocationNumber" attach @focus="focused = true" @blur="focused = false"></v-combobox>
    </v-col>
    <v-col lg="auto" cols="6" class="date-column flex-grow-1">
      <pick-date-with-opening-times
        label="Departure Date"
        :location.sync="pickupLocation"
        :date.sync="pickupDate"
        :min-date="pickupDateMin"
        :flexibleRates.sync="flexibleRates"
        @focus="focused = true" @blur="focused = false"
      >
      </pick-date-with-opening-times>
    </v-col>
    <v-col lg="auto" cols="6" class="date-column flex-grow-1" v-if="!flexibleRates">
      <pick-date-with-opening-times
        label="Return Date"
        :location.sync="pickupLocation"
        :date.sync="returnDate"
        :min-date="returnDateMin"
        :max-date="returnDateMax"
        :flexibleRates.sync="flexibleRates"
        @focus="focused = true" @blur="focused = false"
      >
      </pick-date-with-opening-times>
    </v-col>
    <v-col lg="auto" v-else cols="6" class="date-column">
      <NumberInput label="Nights" v-model="duration" v-bind:required="true" v-bind:min="minDuration" v-bind:max="maxDuration"></NumberInput>
    </v-col>
    <v-col lg="auto" cols="6" class="promo-column flex-shrink-1 flex-grow-0">
        <v-text-field label="Promo Code (Optional)" :prepend-icon="promo_icon" v-model="promoCode"></v-text-field>
    </v-col>
    <v-col lg="auto" cols="6" class="passengers-column flex-shrink-1 flex-grow-0">
        <NumberInput label="Passengers" v-model="passengers" v-bind:required="true" v-bind:min="1" v-bind:max="7"></NumberInput>
    </v-col>
    <v-col lg="auto" md="12" sm="12" cols="12" class="flex-shrink-0 flex-grow-1">
      <v-btn type="submit" @click="submit" color="secondary" :loading="loading" :disabled="loading" class="submit-button elevation-0">Check Pricing <span class="d-sr-only">(content will update below)</span></v-btn>
      <template v-if="resNo">
        <v-btn color="primary" class="newReservationButton elevation-0">New Reservation</v-btn>
      </template>
    </v-col>
  </v-row>
</v-form>
</div>
</div>
</template>

<script>

import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import PickDateWithOpeningTimes from "./PickDateWithOpeningTimes.vue";
import NumberInput from '../components/NumberInput.vue'
import moment from "moment"

import client from 'api-client'
import {pad} from '../tools.js'

export default {
    name: 'ItineraryPage',
    components: {
      NumberInput,
      PickDateWithOpeningTimes
    },
    props: {
      resNo: new String,
    },
    computed: {
      ...mapGetters({
        getFirstOpenDate: 'locations/getFirstOpenDate',
        getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
        getDuration: 'rates/getDuration'
      }),
      ...mapState({
        loading: 'loading',
        rentingStates: state => state.config.rentingStates,
        locations: state => state.config.rentingLocations,
        rates: state => state.rates.rates,
        rateQuery: state => state.rates.rateQuery,
        flexRatesClass: state => state.rates.flexRatesClass,
        minimumPickupDate: state => state.rates.minimumPickupDate,
        minimumRentalPeriod: state => state.rates.minimumRentalPeriod,
        maximumRentalPeriod: state => state.rates.maximumRentalPeriod,
        pickupTime: state => state.rates.pickupTime,
        returnTime: state => state.rates.returnTime,
      }),
      pickupDateMin () {
        return this.formatDate(this.minimumPickupDate);
      },
      returnDateMin () {
        let pickupDateObject = new Date(this.pickupDate);
        let returnDateMinObject = new Date(this.pickupDate);
        returnDateMinObject.setDate(pickupDateObject.getDate() + this.minimumRentalPeriod.asDays());
        return this.formatDate(returnDateMinObject);
      },
      returnDateMax () {
        let pickupDateObject = new Date(this.pickupDate);
        let returnDateMaxObject = new Date(this.pickupDate);
        returnDateMaxObject.setDate(pickupDateObject.getDate() + this.maximumRentalPeriod.asDays());
        return this.formatDate(returnDateMaxObject);
      },
      filteredLocations () {
        let selectedState = this.rateQuery.Pickup.State

        return this.locations.filter(function(loc){
          return loc.StateCode == selectedState
        })
      },
      pickupState: {
        get (){
          return this.rentingStates.find(el=>{
            return el.Code == this.rateQuery.Pickup.State
          })
        },
        set (value){
          if (!value) return
          if (this.pickupLocation && this.pickupLocation.StateCode === value){
            // Don't blank if setting matching state to location
            this.updateRateQuery({Pickup:{State: value}})
          }
          else {
            this.updateRateQuery({Pickup:{State: value, Location: null}})
          }
        }
      },
      pickupLocation:{
          get (){
             return this.locations.find(el=>{
                return el.LocationNumber == this.rateQuery.Pickup.Location
            })
          },
          set (value) {
            if (!value) return
            this.updateRateQuery({Pickup:{Location: value.LocationNumber}})
            // Clear selected RateClass when changing location
            // as we don't know the availability yet
            if (this.rateQuery.FlexibleRates && this.rateQuery.RateClass){
              this.updateRateQuery({RateClass: null })
            }
          }
      },
      flexibleRates: {
        get (){
          return this.rateQuery.FlexibleRates
        },
        set (value){
          this.updateRateQuery({FlexibleRates: value})
        }
      },
      pickupDate:{
          // Note we are convering between two string formats, not involving locale/timezone
          get (){
            return this.rateQuery.Pickup.DateTime.substr(0, 10)
          },
          set (value) {
            // TH-688 If loc closes before pickup, set pickuptime to closing time minus 2 hours
            let openPickup = this.getOpeningTimesForDate(value, this.rateQuery.Pickup.Location)
            let defaultPickupTime = client.initialPickupTime()
            let pickupDateTime = new Date(`${value}T${defaultPickupTime}:00.000Z`)
            let closingTime = "17:00"
            if (openPickup && openPickup[0]){
              closingTime = openPickup[0].EndHour
            }
            let closingDateTime = new Date(`${value}T${closingTime}:00.000Z`)

            if (pickupDateTime > closingDateTime){
              let closingHour = parseInt(closingTime.substring(0,closingTime.indexOf(':')), 10) - 2
              closingHour = pad(closingHour)
              this.setPickupTime(`${closingHour}:00`)
            }
            else {
              this.setPickupTime(defaultPickupTime)
            }

            this.setPickupDate(value);
          }
      },
      returnLocation:{
          get (){
            return this.locations.find(el=>{
                return el.LocationNumber == this.rateQuery.Return.Location
            })
          },
          set (value) {
            if (!value) return
            this.updateRateQuery({Return:{Location: value.LocationNumber}})
          }
      },
      returnDate:{
          get (){
            return this.rateQuery.Return.DateTime.substr(0, 10)
          },
          set (value) {
            this.setReturnDate(value)
          }
      },
      minDuration:{
        get (){
          return Math.ceil(this.minimumRentalPeriod.asDays())
        }
      },
      maxDuration:{
        get (){
          return Math.ceil(this.maximumRentalPeriod.asDays())
        }
      },
      duration: {
        get (){
          return this.getDuration
        },
        set (value){
          let days = parseInt(value, 10);
          this.setDuration(days)
        }
      },
      promoCode: {
        get (){
          return this.rateQuery.PackageCode
        },
        set (value) {
          let uppercased = value.toUpperCase()
          this.updateRateQuery({ PackageCode: uppercased })
        }
      },
      promo_icon () {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return 'redeem';
        }
        return ''
      },
      shrinkMaybe () {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return 'itinerary-row';
        }
        return 'itinerary-row  flex-nowrap'
      },
      passengers: {
        get (){
          return this.rateQuery.Passengers
        },
        set (value) {
          this.updateRateQuery({ Passengers: value })
        }
      }
    },
    methods:{
        ...mapMutations({
          updateReservation: 'reservation/updateReservation',
          updateRateQuery: 'rates/updateRateQuery',
          setSeenOptions: 'setSeenOptions',
          setRates: 'rates/setRates',
          clearReservation: 'reservation/clearReservation',
          setPickupTime: 'rates/setPickupTime',
        }),
        ...mapActions({
          clearRates: 'rates/clearRates',
          setDuration: 'rates/setDuration',
          setPickupDate: 'rates/setPickupDate',
          setReturnDate: 'rates/setReturnDate'
        }),
        formatDate(date){
            return date.toISODateOnlyString();
        },
        submit () {
          // First clear any existing loaded res
          this.clearReservation();
          this.getRates();
        },
        getRates(){
          // Reset seenOptions as there'll be a new Rate
          this.setSeenOptions(false);

          // If not on our host, put request in a query string
          if (document.location.origin !== process.env.VUE_APP_STATIC_CONTENT_URL){
            let q = {}
            q.pickup_datetime = `${this.pickupDate}T${this.pickupTime}`
            q.flexible = this.flexibleRates
            if (this.flexibleRates){
              q.duration = this.duration
            }
            else {
              q.return_datetime = `${this.returnDate}T${this.returnTime}`
            }
            if (this.pickupState){
              q.pickup_state = this.pickupState.Code
            }
            q.pickup_loc = this.pickupLocation.LocationNumber
            q.disc = this.promoCode
            q.passengers = this.passengers

            let route = this.$router.resolve({path: '/rates', query: q })
            let fullpath = document.location.origin+route.href;
            window.open(fullpath, '_self');
            return;
          }

          if (this.$router.currentRoute.path && this.$router.currentRoute.path.match(/rates.*$/)){
            // Already on rates page, just refresh them
            this.$store.dispatch('rates/getRates')
            .then(() => {
              if (this.rateQuery.FlexibleRates && this.flexRatesClass){
                this.$store.dispatch('rates/getMonthOfRates')
              }
            });
          }
          else {
            // Otherwise go back to rates page to choose class
            this.$router.push({path: '/rates'})
          }
        },
        toggleCollapse(){
          this.collapsing = true
          setTimeout(() => {
            this.collapsed = !this.collapsed
          }, 10)
          setTimeout(() => {
            this.collapsing = false
          }, 350)
        },
    },
    data () {
      return {
        collapsed: true,
        collapsing: false,
        pickupDateMenu: false,
        returnDateMenu: false,
        selectedState: '',
        focused: false,
      }
    },
    watch: {
      focused (v){
        this.$emit('focus', v)
      },
      pickupDate (){
        if (this.rates && this.rates.rateQuery && this.pickupDate !== this.rates.rateQuery.Pickup.DateTime.substr(0, 10)){
          this.clearRates();
        }
      },
      returnDate () {
        if (this.rates && this.rates.rateQuery && this.returnDate !== this.rates.rateQuery.Return.DateTime.substr(0, 10)){
          this.clearRates();
        }
      },
      promoCode () {
        if (this.rates && this.rates.rateQuery && this.promoCode !== this.rates.rateQuery.PackageCode){
          this.clearRates();
        }
      },
      duration () {
        this.clearRates();
      },
      passengers () {
        if (this.rates && this.rates.rateQuery && this.passengers !== this.rates.rateQuery.Passengers){
          this.clearRates();
        }
      },
      pickupLocation () {
        // set the return location when the pickup location changes
        this.returnLocation = this.pickupLocation;
        if (this.pickupLocation ){
          this.$store.dispatch("locations/getLocationOpenHours", {
            location: this.pickupLocation.LocationNumber,
            date: this.pickupDate
          })
          .then(()=>{
            if (!this.$route.query.return_datetime && !this.$route.query.duration){
              let d1 = this.getFirstOpenDate(this.pickupDate, this.pickupLocation.LocationNumber)
              this.pickupDate = d1.toISODateOnlyString();
              let d2 = this.getFirstOpenDate(this.returnDateMin, this.pickupLocation.LocationNumber)
              this.returnDate = d2.toISODateOnlyString()
            }
          })
        }

        if (this.rates && this.rates.rateQuery && (!this.pickupLocation || this.pickupLocation.LocationNumber !== this.rates.rateQuery.Pickup.Location)){
          this.clearRates();
        }
      },
    },
    created() {
      // get locations
      this.$store.dispatch("config/rentingStates", { self: this })
      this.$store.dispatch("config/rentingLocations", { self: this })

      let q = this.$route.query
      if (q.iata){
        this.updateReservation({VendorID: q.iata})
      }

      var $vm = this
      // TH-1055 has to be bound by script on cruiseamerica.com
      window.SetBookingBarPromo = function(locationCode, startDate, endDate, promoCode) {

        // MM/DD/YYYY HH:MM:SS AM
        // 9/6/2021 12:00:00 AM

        if (locationCode) {
          var loc = $vm.locations.find(loc=>{
            return loc.LocationNumber === locationCode
          })
          var pickupState = $vm.rentingStates.find(state =>{
            return state.Code === loc.StateCode
          })
          $vm.pickupState = pickupState.Code
          $vm.pickupLocation = loc
        }
        if (promoCode) {
          $vm.promoCode = promoCode
        }
        if (startDate) {
          $vm.pickupDate = moment(startDate, 'MM/DD/YYYY hh:mm:ss A').format('YYYY-MM-DD')
        }
        if (endDate) {
          $vm.returnDate = moment(endDate, 'MM/DD/YYYY hh:mm:ss A').format('YYYY-MM-DD')
        }
        window.scrollTo(0,0)
      }
    }
};

</script>
<style>
.itinerary-row {
  border: 10px solid #d7d7d7;
  background: #fff;
}
.itinerary-row  .v-text-field>.v-input__control>.v-input__slot:before {
  border: 0;
}
.itinerary-row .v-text-field__details, .itinerary-row .v-messages {
  min-height: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.itinerary-row .v-text-field input::placeholder {
  color: #6c757d;
  text-transform: uppercase;
}
.submit-button.v-btn.v-size--default {
  width: 100%;
  height: 100%;
}
.submit-button.v-size--default {
  font-size: 22px;
  font-size: 1.375rem;
}
.submit-button.v-btn:focus {
  border: 10px solid #d7282d;
  background-color: #fff !important;
  color: #d7282d;
}
.submit-button.v-btn:focus::before {
  display: none;
  background-color: #fff;
}
.itinerary-row .v-input {
  margin-left: 5px;
}
.passengers-column, .promo-column, .date-column {
  min-width: 130px;
}
@media (min-width: 1264px) {
  .itinerary-row .v-input {
    margin-right: 5px;
    border-right: 1px solid #d7d7d7;
  }
  .itinerary-row .v-input.noborder {
    border-right: none;
  }
  .itinerary-row .passengers-column .v-input {
    border-right: none;
  }
  .date-column {
    max-width: 150px;
  }
  .location-column {
    max-width: 180px;
  }
}

/* copied from webres.cruiseamerica.com: */

#checkPricingToggle {
    font-size: 25px;
    font-size: 1.5625rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    background-color: #d7282d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding: 0 15px;
    cursor: pointer;
}
@media screen and (min-width: 30em) and (max-width:64em) {
    #checkPricingToggle {
        padding: 0 15px 0 30px
    }
}
#checkPricingToggle::after {
    content: "";
    background-image: url(https://www.cruiseamerica.com/assets/img/svg/icon-arrow-down-white.svg);
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 15px 8px;
    display: inline-block;
    min-width: 15px;
    min-height: 15px;
    margin-right: 10px
}
@media screen and (min-width: 64em) {
    #checkPricingToggle {
        display:none
    }
}
#moduleCheckPricing {
  position: relative;
}
#moduleCheckPricing.collapsing {
  overflow: hidden;
  max-height: 300px;
  transition: max-height .35s ease;
}
@media screen and (max-width: 64em) {
    #moduleCheckPricing.collapsed {
      max-height: 0;
      overflow: hidden;
    }
}

</style>
