import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '../sass/main.scss'
import '../sass/main.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        primary: '#3273AF',
        secondary: '#D7282D',
        anchor: '#3273AF',
        accent: '#3273AF',
        error: '#D7282D'
      },
    },
  },
})
export default vuetify