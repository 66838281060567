import client from 'api-client'

const state = {
  countries: [],
  addressStates: [],
  rentingStates: [],
  rentingLocations: [],
  errorMessages: [],
  vehicleDescriptions: {},
  optionDetails: {},
}

const getters = {
}

const mutations = {
  setCountries (state, countries) {
    state.countries = countries
  },
  setAddressStates (state, addressStates) {
    state.addressStates = addressStates
  },
  setRentingStates (state, rentingStates){
    state.rentingStates = rentingStates
  },
  setRentingStatesCruise (state, rentingStatesCruise){
    // Replace our states list with this one
    // Re-order the data to match our schema
    let newstates = [];
    rentingStatesCruise.forEach(function(state){
      let newstate = {
          "Code": state.StateCode,
          "Name": state.Name,
          "Currency": "USD"
      }
      if (newstate.CountryCode === "ca"){
        newstate.Currency = "CAD";
      }
      newstates.push(newstate);
    });
    state.rentingStates = newstates;
  },
  setRentingLocations (state, locations) {
    state.rentingLocations = locations
  },
  setLocationsCruise (state, locations){
    let newlocs = [];
    locations.forEach(function(loc){
      let newloc = {
        "Description": loc.City,
        "CountryCode": loc.CountryCode,
        "StateName": loc.StateName,
        "LocationNumber": loc.LocationCode,
        "StateCode": loc.StateAbrv,
        "Address":
        {
            "Street1": loc.Name,
            "Street2": loc.Address,
            "Street3": loc.Zip,
            "MainPhone": loc.MainPhone,
            "ReservationPhone": loc.ReservationPhone,
            "City": loc.City,
            "State": loc.StateAbrv
        }
      };
      if (loc.ReservationPhone){
        newloc.Address.Phone = loc.ReservationPhone;
      }
      newlocs.push(newloc);
    });
    state.rentingLocations = newlocs;
  },
  setErrorMessages (state, errorMessages){
    state.errorMessages = errorMessages
  },
  setVehicleDescriptions (state, vehicleDescriptions){
    state.vehicleDescriptions = vehicleDescriptions
  },
  setOptionDetails (state, optionDetails){
    state.optionDetails = optionDetails
  },
}

const actions = {
  countries ({ commit }) {
    return client
      .countries()
      .then((countries) => {
        commit('setCountries', countries);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
  },
  addressStates({commit}){
    return client
      .addressStates()
      .then((file) => {
        if (!file.States){
          let error = {"response":{"data":{"Text":"badly formed address_states.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setAddressStates', file.States);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
  rentingStates({dispatch, commit}){
    return client
      .rentingStates()
      .then((file) => {
        if (!file.RentingStates){
          let error = {"response":{"data":{"Text":"badly formed renting_states.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setRentingStates', file.RentingStates);
        dispatch('rentingStatesCruise');
        return Promise.resolve()
      }, error => {
        commit('setErrors', error);
        return Promise.reject(error)
      });
  },
  rentingStatesCruise({commit}){
    return client
      .rentingStatesCruise()
      .then((states) => {
        if (!states.length){
          let error = {"response":{"data":{"Text":"badly formed crusie states api response"}}};
          commit('setErrors', error);
          return Promise.reject(error)
        }
        commit('setRentingStatesCruise', states);

        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },

  rentingLocations ({dispatch, commit}) {
    return client
      .locations()
      .then(locations => {
        if (!locations.Locations){
          let error = {"response":{"data":{"Text":"badly formed locations.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setRentingLocations', locations.Locations);
          dispatch('locationsCruise');
          return Promise.resolve()
        }, error => {
          commit('setErrors', error);
          return Promise.reject(error)
        })
    },
    locationsCruise ({commit}) {
      return client
        .locationsCruise()
        .then(locations => {
          if (!locations.length){
            let error = {"response":{"data":{"Text":"badly formed cruise locations api response"}}};
            commit('setErrors', error);
            return Promise.reject(error)
          }
          commit('setLocationsCruise', locations);
          return Promise.resolve()
        }, error => {
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        })
  },
  errorMessages({commit}){
    return client
      .errorMessages()
      .then((errorMessages) => {
        if (!errorMessages.Errors){
          let error = {"response":{"data":{"Text":"badly formed errors.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
      commit('setErrorMessages', errorMessages.Errors);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
  vehicleDescriptions({commit}){
    return client
      .vehicleDescriptions()
      .then((vehicleDescriptions) => {
        if (!vehicleDescriptions.Vehicles){
          let error = {"response":{"data":{"Text":"badly formed vehicles.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setVehicleDescriptions', vehicleDescriptions.Vehicles);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
  getOptionDetails({commit}){
    return client
      .optionDetails()
      .then((optionDetails) => {
        if (!optionDetails.Options){
          let error = {"response":{"data":{"Text":"badly formed options.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setOptionDetails', optionDetails.Options);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}