import client from 'api-client'
import {mergeDeep} from "../../tools.js";

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const maxDate = new Date().addDays(3*365);

const state = {
  locationOpenHours: {},
  loadingOpeningHours: false,
}

const getters = {
  getOpeningTimesForDate: (state) => (dateString, locationNumber) => {
    let date = new Date(dateString);
    let openings = state.locationOpenHours[locationNumber];
    if (!openings) return;
    for (let i = openings.length - 1; i > -1; i--) {
      let d = openings[i];
      if (date >= new Date(d.StartDate) && date < new Date(d.EndDate)) {
        let dayOfWeek = date.getUTCDay();
        return d[weekDays[dayOfWeek]]
      }
    }
    return null
  },
  getFirstOpenDate: (state) => (dateString, locationNumber) => {
    let date = new Date(dateString);
    let openings = state.locationOpenHours[locationNumber];
    if (!openings){
      return
    }

    while (date<maxDate) {
      for (let i = openings.length - 1; i > -1; i--) {
        let d = openings[i];

        if (date >= new Date(d.StartDate) && date < new Date(d.EndDate)) {
          let dayOfWeek = date.getUTCDay();

          let v = d[weekDays[dayOfWeek]];
          if (v == null) {
            date = date.addDays(1);
            i = openings.length;
            continue
          }
          return date
        }
      }
      // there's no opening date record so the location must be closed
      date = date.addDays(1);
    }
    // must be open now, so return the supplied date
    return new Date(dateString);
  }
}

const mutations = {
  setLocationOpenHours(state, {location, openHours}){
    let obj = {};
    obj[location] = openHours;
    mergeDeep(state.locationOpenHours, obj)
  },
  setLoadingOpeningHours (state, value){
    state.loadingOpeningHours = value
  },
}

const actions = {
  getLocationOpenHours({commit}, {location, startDate}){
    commit('setLoadingOpeningHours', true);
    // TODO: check if opening hours already exist in the state for the selected location & startDate
    return client
      .openingHours({location, startDate})
      .then(openHours => {
        commit('setLocationOpenHours', {location, openHours});
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoadingOpeningHours', false)
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}