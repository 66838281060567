<template>
  <v-col cols="12" lg="5" class="estimate pr-8" v-if="!loading">
    <h2 class="mb-4 text-h5">Estimate</h2>
    <v-row>
      <v-col cols="6">{{ duration }} Nights</v-col>
      <v-col cols="6" class="text-right font-weight-bold">{{ estimate.Customer.TimeCharges[0].SubTotal | price }}</v-col>
    </v-row>
    <v-row v-if="showDistance">
      <v-col cols="6">{{ distanceEstimated }} Estimated {{ distanceUnit }}</v-col>
      <v-col cols="6" class="text-right font-weight-bold">{{ distanceTotal | price }}</v-col>
    </v-row>

    <v-row v-for="option in filteredOptions" v-bind:key="option.Code">
      <template v-if="(option.Quantity > 1)">
        <v-col cols="3">{{ option.Description | sentencecaps }}</v-col>
        <v-col cols="3">x {{ option.Quantity }}</v-col>
      </template>
      <template v-else>
        <v-col class="description">{{ option.Description | sentencecaps }}</v-col>
      </template>
      <v-col class="text-right font-weight-bold">
        <template v-if="(option.Total !== 0)">{{ option.SubTotal | price }}</template>
        <template v-else>No Charge</template>
      </v-col>
    </v-row>

    <v-row class="mb-1 px-3">
      <v-divider role="presentation"></v-divider>
    </v-row>

    <v-row>
      <v-col>Sub Total</v-col>
      <v-col class="text-right font-weight-bold">{{ preTaxSubTotal | price }}</v-col>
    </v-row>

    <v-row v-for="charge in combinedTaxCharges" v-bind:key="charge.Code">
      <v-col class="description">{{ charge.Description | sentencecaps }}</v-col>
      <v-col class="text-right font-weight-bold">
        <template v-if="charge.Total">{{ charge.Total | price }}</template>
        <template v-else>No Charge</template>
      </v-col>
    </v-row>

    <v-row class="mb-1 px-3">
      <v-divider role="presentation"></v-divider>
    </v-row>

    <v-row >
      <v-col class="font-weight-bold">Total Cost of Rental</v-col>
      <v-col class="text-right font-weight-bold">{{ totalCostOfRental | price }}</v-col>
    </v-row>
    <v-row>
      <v-col class="">Refundable Security Deposit (Due at Pick Up)</v-col>
      <v-col class="text-right font-weight-bold">{{ 500 | price }}</v-col>
    </v-row>
    <v-row>
      <v-col class="">Reservation Downpayment ({{ dueOrPaid }})</v-col>
      <v-col class="text-right font-weight-bold">{{ -300 | price }}</v-col>
    </v-row>

    <v-row class="px-3">
      <v-divider role="presentation"></v-divider>
    </v-row>

    <v-row class="pt-2">
      <v-col>Total Due at Pick Up</v-col>
      <v-col class="text-right font-weight-bold">{{ totalDueAtPickUp | price }}</v-col>
    </v-row>

    <v-row class="mb-1 px-3">
      <v-divider role="presentation"></v-divider>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'
import moment from "moment"

export default {
    name: 'EstimateSummary',
    props: {
      createdRes: String
    },
    computed: {
    ...mapState({
      loading: 'loading',
      locations: state => state.config.rentingLocations,
      estimate: state => state.reservation.estimate,
      reservation: state => state.reservation.reservation,
    }),
    filteredTimeCharges () {
      return this.estimate.Customer.TimeCharges.filter(charge => charge.Total)
    },
    taxTotal (){
      return Object.values(this.combinedTaxCharges).reduce((charge, {Total}) => {
        return charge + (Number(Total) || 0)
      }, 0)
    },
    preTaxSubTotal () {
      // calculate the pre-tax, pre-security deposit SubTotal
      if (!this.estimate.Customer.Total || !this.estimate.Customer.TaxCharges){
        return 0
      }
      return this.estimate.Customer.Total - this.taxTotal - 500
    },
    totalCostOfRental (){
      // the post-tax cost minus $500 security deposit
      if (!this.estimate.Customer.Total){
        return 0
      }
      return this.estimate.Customer.Total - 500
    },
    totalDueAtPickUp () {
      if (this.estimate.Customer.Total){
        return this.estimate.Customer.Total - 300
      }
      return 0
    },
    filteredOptions () {
      return this.estimate.Customer.Options.filter(charge => {
        // hide cruise-specific hack.
        if (charge.Code === 'SLI'){
          charge.Total = 0
          return true
        }
        else {
          return charge.Code !== 'PROM0' && charge.Code !== 'SECURITY' && charge.Total && charge.IncludeInSubTotal === true
        }
      })
    },
    combinedTaxCharges () {
      // combine the tax charges with options that have 'IncludeInSubTotal' flag
      let optionsIncludedInTaxCharges = this.estimate.Customer.Options.filter(charge => {
        return !charge.IncludeInSubTotal && charge.Code !== 'SLI' && charge.Code !== 'PROM0' && charge.Quantity !== undefined
      })
      if (!this.estimate.Customer.TaxCharges){
        return optionsIncludedInTaxCharges
      }
      return this.estimate.Customer.TaxCharges.concat(optionsIncludedInTaxCharges)
    },
    pickupLocation (){
      if (!this.reservation.Pickup){
        return {};
      }
      let pickupLocCode = this.reservation.Pickup.Location;
      return this.locations.find(loc =>{
        return loc.LocationNumber == pickupLocCode
      })
    },
    dueOrPaid () {
      if (this.reservation.ReservationStatus === "new"){
        return 'Due Today'
      }
      else {
        return 'Paid'
      }
    },
    showDistance(){
      // hide distance on trailers
      if (this.reservation.Rate.Rental.Distance.ExtraRate === 0 || this.reservation.RateClass.indexOf('TR') === 0){
        return false
      }
      return true
    },
    distanceUnit () {
      if (this.pickupLocation.CountryCode === 'CA'){
        return 'Kilometers';
      }
      return 'Miles';
    },
    distanceRate () {
      if (this.reservation.Rate.Rental && this.reservation.Rate.Rental.Distance){
        return this.reservation.Rate.Rental.Distance.ExtraRate
      }
      return 0;
    },
    distanceEstimated () {
      return this.reservation.EstimatedDistance;
    },
    distanceIncluded (){
      if (this.reservation.Rate.Rental && this.reservation.Rate.Rental.Distance){
        return this.reservation.Rate.Rental.Distance.Included || 0
      }
      return 0;
    },
    distanceTotal () {
      return (this.distanceEstimated - this.distanceIncluded) * this.distanceRate
    },
    distanceLink () {
      let country = this.pickupLocation.CountryCode;

      let currency = 'USD';
      if (country === 'CA'){
        currency = 'CAD';
      }
      let rate = this.estimate.Customer.Distance.RateExclTax;
      let unit = this.estimate.Customer.Distance.Unit;
      let distance = this.distanceEstimated;
      let duration = this.duration;
      return `https://www.cruiseamerica.com/rent/webres/miles?country=${country}&currency=${currency}&rate=${rate}&unit=${unit}&distance=${distance}&nights=${duration}`;
    },
    ratePerNight(){
      return this.reservation.Rate.Rental.RateOnlyEstimate / this.duration
    },
    duration (){
      if (!this.reservation.Pickup){
        return 0
      }
      // calculate res duration separate to rate duration
      // otherwise changing the itinerary changes the displayed duration
      let a = moment(this.reservation.Return.DateTime.substring(0,10));
      let b = moment(this.reservation.Pickup.DateTime.substring(0,10));
      return Math.ceil(a.diff(b,'days', true));
    },
    ReservationNumber (){
      return this.reservation.ReservationNumber
    },
  },
  methods: {
    popup (event) {
      let href = event.currentTarget.href
      window.open(href, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=480,height=300')
    },
    logAnalytics(ReservationNumber){
      // send details to google analytics tag manager
      if (window.dataLayer){
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          'ecommerce': {
            'purchase': {
              'actionField': {
                'currency': this.currency,
                    'id': ReservationNumber,                     // Reservation number. Text field. Required.
                'affiliation': this.reservation.Pickup.Location, // Pick-up location variable. Text field.
                'revenue': this.preTaxSubTotal,                  // Total revenue excluding taxes
                'coupon': this.reservation.PackageCode           // Deal code if applicable. Text field.
              },
              'products': [{
                'name': this.reservation.RateClass,              // Class
                'price': this.ratePerNight,                      // Cost per night. Numerical value.
                'quantity': this.duration,                       // Number of nights. Numerical value.
                'metric1': this.distanceEstimated                // Estimated Miles. Numerical value.
               }]
            }
          }
        });

      }
    },
  },
  filters: {
    sentencecaps: function (value) {
      if (!value) return ''
      let sentence = value.toString().toLowerCase().split(' ')
      .map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1))
      })
      return sentence.join(' ')
    }
  },
  watch: {
    ReservationNumber(ReservationNumber){
      if (ReservationNumber){
        this.logAnalytics(ReservationNumber)
      }
    }
  },

};

</script>
<style>
.estimate .col {
  padding-top: 0px;
  padding-bottom: 7.5px;
}
</style>