<template>
  <v-layout row>
    <v-container>
      <v-card class="py-6 mb-4 elevation-0">
        <v-row>
          <h1 class="d-sr-only">Checkout</h1>
          <EstimateSummary :createdRes="createdRes" class="ps-12"></EstimateSummary>
          <TripDetails class="ps-12"></TripDetails>
        </v-row>
      </v-card>
    <v-row>
      <v-form v-model="valid" v-on:submit.prevent="submit">

      <v-row>
        <v-col cols="12" lg="6">
          <v-card-title><h2 class="text-h5">Driver Details</h2></v-card-title>
          <v-card-text>
            <ContactForm v-bind:Contact="reservation.Renter"></ContactForm>
          </v-card-text>

          <v-card-title><h2 class="text-h5">Address</h2></v-card-title>
          <v-card-text>
            <AddressForm v-bind:Address="reservation.Renter.Address"></AddressForm>
          </v-card-text>
        </v-col>
        <v-col cols="12" lg="6">
          <template>
            <v-card-title><h2 class="text-h5">Payment</h2></v-card-title>
            <v-card-text>
              <template v-if="estimate.Customer.Total">
                Please pay $300 deposit
              </template>
              <CardPaymentForm v-bind:Deposit="reservation.Deposit"></CardPaymentForm>
            </v-card-text>
          </template>
        </v-col>

      </v-row>
        <v-card-text>
          <h2 class="text-h5">Terms and Conditions</h2>
<p>VEHICLE GENERATOR FEE: If equipped, vehicle generator hours are charged at $3.50 per hour.</p>

<p>RESERVATION CANCELLATION FEES: With 7 days or more notice - no fee. With less than 7 days notice - forfeiture of $300 reservation deposit. For special events (Burning Man, music festivals) with less than 45 days of notice - forfeiture of all deposits. Cruise America and the customer reserve the right to cancel at any time.</p>

<p>Rent to Buy - Apply up to two weeks time and distance charges towards the purchase of a Cruise America Motorhome.</p>

<p>Cruise America rentals in U.S. dollars. Cruise Canada rentals in Canadian Dollars. Both Cruise America and Cruise Canada do not guarantee a specific floorplan and reserve the right to substitute similar or higher rated vehicles. All vehicles must be operated by properly licensed and authorized drivers, 21 years of age or over, who must be present at departure to sign Rental Agreement. All charges are payable in advance.</p>

<p>
For the purpose of renting vehicles from you, I certify that the above information is true and complete to the best of my knowledge. I authorize you to charge prepayments/deposits on my credit card. This application is not accepted until approved by a representative of lessor. All prepayments/deposits will be refunded if application is not approved. This application does not constitute a contract between parties. Reservation applicant must sign a Rental Agreement at departure.
</p>
        </v-card-text>
        <v-card-text>
          <v-checkbox label="I agree to the Terms and Conditions" required :rules="TermsChecked"></v-checkbox>
        </v-card-text>
        <v-card-text>
          <p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="whitebutton elevation-0 mb-4" ref="submitbutton" type='submit' :disabled="!enableSubmit" :loading="loading">Book Now</v-btn>
        </v-card-actions>
      </v-form>
    </v-row>
  </v-container>
</v-layout>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import EstimateSummary from '../components/EstimateSummary.vue'
import ContactForm from '../components/ContactForm.vue'
import AddressForm from '../components/AddressForm.vue'
import CardPaymentForm from '../components/CardPaymentForm.vue'
import TripDetails from '../components/TripDetails.vue'

export default {
  name: 'CheckoutPage',
  components: {
    EstimateSummary,
    ContactForm,
    AddressForm,
    CardPaymentForm,
    TripDetails
  },
  computed: {
    ...mapState({
      loading: 'loading',
      estimate: state => state.reservation.estimate,
      reservation: state => state.reservation.reservation,
    }),
    enableSubmit(){
      return this.valid && !this.submitting
    },
  },
  methods:{
    ...mapMutations({
      updateReservation: 'reservation/updateReservation'
    }),
    ...mapActions({
      createReservation: 'reservation/createReservation',
      getEstimate: 'reservation/getEstimate',
    }),
    submit () {
      this.submitting = true

      // Update on the state
      this.updateReservation({
        Renter: this.reservation.Renter,
        Deposit: this.reservation.Deposit
      })

      // Determine whether to save now or go back to Summary page
      if (this.reservation.ReservationStatus === "new"){
        let resRequest = {
          RecaptchaToken: this.recaptchaToken,
          optionsRequestId: this.$store.state.options.optionsRequestId,
          params: this.reservation
        }
        // create or update depending on res state
        this.createReservation(resRequest)
        .then((response) => {
          this.$router.push({path: `/confirmation/${response.ReservationNumber}`})
          this.createdRes = response.ReservationNumber
        })
        .finally(()=>{
          this.submitting = false;
        })
      }
      else {
        this.$router.push({path: `/reservation/${this.reservation.ReservationNumber}`})
      }
    }
  },
  data () {
    return {
      recaptchaToken: null,
      submitting: false,
      valid: false,
      createdRes: '',
      TermsChecked: [
        v => !!v || 'Please check the box to agree to the terms and conditions'
      ]
    }
  },
  created () {
    // Check we have chosen a rate to book
    if (!this.reservation.ReservationNumber && !this.reservation.Rate){
      // Redirect to the rates page
      this.next('/rates');
    }
    // Update estimate for the current state
    this.getEstimate();
  }
};
</script>