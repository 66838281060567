<template>
  <v-row>
    <v-col cols="12" md="4" sm="6" class="pa-4 mb-3 mb-md-0">
      <v-carousel height="250" hide-delimiters class="vehcarousel" aria-hidden="true">
        <v-carousel-item
          v-for="(item, i) in vehicle.Images"
          :key="i"
          :src="item.src"
          :alt="item.Name">
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col>
      <h2 class="text-h3 headline mb-2">{{ vehicle.Title }}</h2>
      <ul class="featurelist">

        <li class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center" v-if="vehicle.Passengers">
          <img src="https://cruise.azureedge.net/cruise/images/webres/bed.png" class="iconimage mx-auto" alt="">
          <div> Sleeps: {{ vehicle.Passengers }} </div>
        </li>

        <li v-if="vehicle.Description" class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/info.png" class="iconimage mx-auto" alt="">
          <div v-html="vehicle.Description"></div>
        </li>
        <li v-if="generator" class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/generator.png" class="iconimage mx-auto" alt="">
          <div> Generator </div>
        </li>

        <li class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/gas.png" class="iconimage mx-auto" alt="">
          <div> Gas Cooktop </div>
        </li>

        <li class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/fridge.png" class="iconimage mx-auto" alt="">
          <div> Refrigerator </div>
        </li>

        <li class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/micro.png" class="iconimage mx-auto" alt="">
          <div> Microwave </div>
        </li>

        <li class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/toilet.png" class="iconimage mx-auto" alt="">
          <div> Toilet </div>
        </li>

        <li class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/shower.png" class="iconimage mx-auto" alt="">
          <div> Shower </div>
        </li>

        <li v-if="vehicle.Storage" class="feature-icons col-6 col-sm-4 mb-3 col-md-2 text-center">
          <img src="https://cruise.azureedge.net/cruise/images/webres/luggage-space.png" style="max-width:50px;height:auto;" class="iconimage mx-auto" alt="">
          <div><span v-html="vehicle.Storage"></span> </div>
        </li>

      </ul>

    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'VehicleDetails',
  computed: {
    ...mapState({
      vehicleDescriptions: state => state.config.vehicleDescriptions,
      reservation: state => state.reservation.reservation,
      locations: state => state.config.rentingLocations
    }),
    vehicle () {
      var desc = this.vehicleDescriptions[this.rate.Class];
      if (!desc){
        // return a default desc object
        return {
          Title: this.rate.Class,
          Images: ['vehicle.png'],
          Description: '',
          Generator: {
            "CA": false,
            "US": false
          }
        };
      }
      else {
        return desc;
      }
    },
    pickupLocation () {
      if (!this.reservation || !this.reservation.Pickup){
        // return something to default to keep 'generator' working
        return {
          CountryCode: "US"
        }
      }
      return this.locations.find(el=>{
          return el.LocationNumber == this.reservation.Pickup.Location
      })
    },
    generator () {
      // Generator varies by country
      let country = this.pickupLocation.CountryCode
      return this.vehicle.Generator[country]
    }
  },
  props: {
    rate: {
      Class: String,
      Availability: String,
      Rental: {
        Distance: {
          ExtraRate: Number,
          Unit: String
        },
        Estimate: Number,
        RateOnlyEstimate: Number
      }
    }
  }
};
</script>
<style>
.vehcarousel {
  max-width: 345px;
  margin: auto;
}
ul.featurelist {
  list-style: none;
  padding: 0;
  margin: 0;
  display:flex;
  flex-wrap: wrap;
}
</style>